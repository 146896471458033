import * as React from "react";
import { useState } from "react";
// @ts-ignore
import { Spinner } from "reactstrap";
import { useCompaniesWithProjects } from "../../../../hooks/useCompaniesWithProjects";
import {
  CandidateImportParseResult,
  candidateImportStrategyOptions,
  candidateService,
} from "../../../../screens/projects/CandidateService";
import { Select, SelectOption } from "../../../DiscoverNew/Form/Select/Select";
import SectionTitle from "../../../SectionTitle/SectionTitle";
import { Alert } from "../../../ui/Alert/Alert";
import { UploadFileCard } from "../../../ui/UploadFileCard/UploadFileCard";

import css from "./CandidateImport.module.css";
import { CandidateImportFooter } from "./CandidateImportFooter";

export type CandidateImportUploadStepValue = {
  file?: File
  project?: SelectOption
  importStrategy: SelectOption
}

type Props = {
  value: CandidateImportUploadStepValue
  onSubmit: (v: CandidateImportUploadStepValue, parseResult: CandidateImportParseResult) => void
}

export const CandidateImportUpload = (props: Props) => {
  const [importStrategy, setImportStrategy] = React.useState(props.value.importStrategy);
  const [project, setProject] = useState(props.value.project)
  const [file, setFile] = useState(props.value.file);
  const projectOptions = useCompaniesWithProjects();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async () => {
    if (!file) {
      setError('Please select a file to upload candidates');
      return;
    }

    setLoading(true);
    try {
      const parseResult = await candidateService.parseCandidateImport(file);
      setLoading(false)
      props.onSubmit({
        file,
        project,
        importStrategy
      }, parseResult);
    } catch (err) {
      setLoading(false);
      setError('Failed to parse the file. Please make sure the file is in the correct format');
    }
  }

  return (
    <>
      <div className='max-width'>
        {!!error && <Alert style={{ marginTop: 20, marginBottom: 20, fontSize: 14 }}>{error}</Alert>}
        {loading ? (
          <div className={css.loader}>
            <Spinner color="primary" />
            <SectionTitle level={3}>Give us a moment to upload your data...</SectionTitle>
          </div>
        ) : (
          <>
            <div className={css.section}>
              <SectionTitle className={css.sectionTitle} level={2}>Upload your file</SectionTitle>
              <UploadFileCard value={file} onChange={(v) => {
                setFile(v);
                setError('');
              }} />
            </div>
            <div className={css.section}>
              <SectionTitle className={css.sectionTitle} level={2}>How would you like to import your
                contacts?</SectionTitle>
              <Select onChange={v => setImportStrategy(v as SelectOption)} value={importStrategy}
                      options={candidateImportStrategyOptions} />
            </div>
            <div className={css.section}>
              <SectionTitle className={css.sectionTitle} level={2}>Select project (optional)</SectionTitle>
              <Select isClearable onChange={v => setProject(v as SelectOption)} value={project} options={projectOptions} />
            </div>
          </>
        )}
      </div>
      <CandidateImportFooter nextButtonDisabled={loading} onNext={onSubmit} />
    </>
  )
}