import { css } from "emotion/macro";
import React from "react";
import { Modal } from "react-bootstrap";
import CloseButton from "../../../button/CloseButton";
import { modalStyles } from "../../../modals/modalStyles";
import CandidateForm from "./CandidateForm/CandidateForm";

type Props = {
  onClose: () => void
  onSuccess: () => void
  candidateId?: number
}

enum AddCandidateMode {
  manual = 'manual',
  importFromService = 'importFromService',
  uploadFile = 'uploadFile',
  copyPaste = 'copyPaste',
}

export const ModalCandidateForm: React.FC<Props> = ({ onClose, onSuccess, candidateId }) => {
  return (
    <Modal
      show={true}
      onHide={onClose}
      centered={true}
      className={css`
        .modal-content {
          min-height: initial;
        }
      `}
    >
      <CloseButton onClick={onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <div className={modalStyles.modalTitle}>{candidateId ? 'Edit Candidate' : 'Add Candidate'}</div>
        {!candidateId && <div className={modalStyles.modalSubtitle}>Type the information to manually add a candidate.</div>}
        <div className={modalStyles.modalFormContainer}>
          {/*{mode === AddCandidateMode.manual && <AddCandidateManually onSuccess={onClose} />}*/}
          <CandidateForm candidateId={candidateId} onSuccess={onSuccess} />
        </div>
      </div>
    </Modal>
  );
};