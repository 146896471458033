import * as React from "react";
import { Link as CustomLink } from "../../../components/DiscoverNew/UI/Link/Link";
import { Formatter } from "../../../components/DiscoverNew/util/Formatter";
import Table from "../../../components/project/Table/Table";
import { PaginationFull } from "../../../components/DiscoverNew/UI/PaginationFull/PaginationFull";
import { DEFAULT_PAGE_SIZE } from "../../../constants/constants";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import { UrlHelper } from "../../../components/DiscoverNew/util/UrlHelper";
import { omit, pick } from "../../../components/DiscoverNew/util/util";
import { debounce } from "lodash";
import { projectService } from "../../projects/ProjectService";
import { AdminOrganizationListFilters } from "./AdminOrganizationListFIlters/AdminOrganizationListFilters";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { withConfirm } from "../../../components/hoc/withConfirm";

import css from "./AdminOrganizationList.module.css";
import { organizationService } from "../../../services/OrganizationService";
import { adminService } from "../../../services/AdminService";

const sortOptions = [
  { value: "REGISTERED_DESC", label: "Created (desc)", field: "created_at", dir: "DESC" },
  { value: "REGISTERED_ASC", label: "Created (asc)", field: "created_at", dir: "ASC" },
  { value: "NAME_ASC", label: "Name (asc)", field: "name", dir: "ASC" },
  { value: "NAME_DESC", label: "Name (desc)", field: "name", dir: "DESC" },
];

export class AdminOrganizationList extends React.Component {
  cleanOverride = () => null;

  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      loading: false,
      error: undefined,
      filters: this.getDefaultFilters(),
      pagination: this.getDefaultPagination(),
      organizationsData: undefined,
      selectedOrganization: undefined,
      selectedOrganizations: {},
    };
  }

  getDefaultFilters() {
    return {
      query: "",
      sort: sortOptions[0],
    };
  }

  getDefaultPagination() {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  componentWillUnmount() {
    this.cleanOverride();
  }

  async componentDidMount() {
    await this.restoreSearchFromUrl();
    this.cleanOverride = injectStyleOverride();
    try {
      await this.fetchData();
      this.setState({ pageLoading: false });
    } catch (err) {
      console.error("couldn't load the page", err);
      this.setState({ pageLoading: false });
    }
  }

  restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};
    const defaultPagination = this.getDefaultPagination();
    const defaultFilters = this.getDefaultFilters();

    return new Promise((resolve) => {
      this.setState(
        {
          filters: {
            ...defaultFilters,
            ...pick(filters, Object.keys(defaultFilters)),
            sort: filters.sort
              ? sortOptions.find((item) => item.value === filters.sort) || defaultFilters.sort
              : defaultFilters.sort,
          },
          pagination: {
            currentPage: parseInt(currentPage || defaultPagination.currentPage),
            pageSize: parseInt(pageSize || defaultPagination.pageSize),
          },
        },
        resolve
      );
    });
  };

  saveSearchToUrl() {
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;
    const activeFilters = pick(filters, Object.keys(filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["sort"]),
      sort: activeFilters.sort.value,
      pageSize,
      currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  }

  debouncedSaveSearchToUrl = debounce(this.saveSearchToUrl, 200);

  fetchData = async () => {
    const { query, sort } = this.state.filters;
    const { pageSize, currentPage } = this.state.pagination;
    this.setState({ loading: true });
    try {
      const organizations = await adminService.fetchOrganizationAll({
        query,
        limit: pageSize,
        skip: (currentPage - 1) * pageSize,
        orderBy: sort.field,
        sortBy: sort.dir.toLowerCase(),
      });
      this.setState({ organizations, loading: false });
    } catch (err) {
      console.error("Couldn't fetch the organizations", err);
      this.setState({
        error: err,
        loading: false,
      });
    }
  };

  debouncedFetchData = debounce(this.fetchData, 250);

  onChangeFilter = (filter) => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filter,
        },
        pagination: {
          ...this.state.pagination,
          currentPage: 1,
        },
      },
      () => {
        if (filter.query) {
          this.debouncedFetchData();
          this.debouncedSaveSearchToUrl();
        } else {
          this.fetchData();
          this.saveSearchToUrl();
        }
      }
    );
  };

  onChangePagination = (pagination) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      },
      () => {
        this.saveSearchToUrl();
        this.fetchData();
      }
    );
  };

  onSelectOrganization = (user) => {
    const { selectedOrganizations } = this.state;
    if (selectedOrganizations[user.id]) {
      this.setState({ selectedOrganizations: omit(selectedOrganizations, [user.id]) });
    } else {
      this.setState({
        selectedOrganizations: {
          ...selectedOrganizations,
          [user.id]: user,
        },
      });
    }
  };

  isAllOrganizationsSelected = () => {
    const { organizations, selectedOrganizations } = this.state;
    return !!organizations?.nodes?.length && Object.keys(selectedOrganizations).length === organizations?.nodes?.length;
  };

  onSelectAllOrganizations = () => {
    if (this.isAllOrganizationsSelected()) {
      this.setState({ selectedOrganizations: {} });
    } else {
      this.setState({
        selectedOrganizations: this.state.organizations?.nodes?.reduce((acc, item) => {
          return {
            ...acc,
            [item.id]: item.id,
          };
        }, {}),
      });
    }
  };

  deleteOrganizations = async () => {
    // @todo reset current page if all organizations from the page have been removed
    const { selectedOrganizations } = this.state;
    const { notifications, confirm } = this.props;
    const organizationsCount = Object.keys(selectedOrganizations).length;
    const organizationsText = organizationsCount > 1 ? `${organizationsCount} organizations` : Formatter.fullName(Object.values(selectedOrganizations)[0]);

    const isConfirmed = await confirm.open({
      destructive: true,
      content: <>Are you sure you want to delete {organizationsText}?</>,
      confirmButtonTitle: "Delete",
    });
    if (!isConfirmed) {
      return;
    }

    try {
      for (const userId of Object.values(selectedOrganizations)) {
        await projectService.delete(userId);
      }
      notifications.showSuccess(`${organizationsText} ${organizationsCount > 1 ? "have" : "has"} been removed!`);
      this.setState({ selectedOrganizations: {} });
      this.fetchData();
    } catch (err) {
      notifications.showError(`Couldn't delete the ${organizationsText}!`);
    }
  };

  render() {
    const { organizations, selectedOrganizations, selectedOrganization, pageLoading, error } = this.state;
    const { pageSize, currentPage } = this.state.pagination;
    const { query, sort } = this.state.filters;

    return (
      <>
        <AdminOrganizationListFilters
          organizations={organizations}
          onChangeQuery={(v) => this.onChangeFilter({ query: v })}
          onChangeSort={(v) => this.onChangeFilter({ sort: v })}
          query={query}
          sortOptions={sortOptions}
          sort={sort}
          selectedOrganizations={selectedOrganizations}
          onDeleteOrganizations={this.deleteOrganizations}
        />
        <div className={css.container}>
          <Table
            noData="No organizations found"
            onSelectAll={this.onSelectAllOrganizations}
            isAllSelected={this.isAllOrganizationsSelected()}
            selected={selectedOrganizations}
            onSelect={this.onSelectOrganization}
            dataObject={{
              loading: pageLoading,
              error,
              data: {
                organizations: organizations?.nodes,
              },
            }}
            columns={[
              {
                className: css.tdName,
                dataIndex: "name",
                style: { width: 150 },
                render: (v, item) => {
                  return (
                    <CustomLink className='table-link' to={`/admin/organizations/${item.id}`}>
                      {Formatter.fullName(item) || item.name?.trim() || item.email}
                    </CustomLink>
                  );
                },
              },
              {
                dataIndex: "hasActiveSubscription",
                title: 'Active Subscription',
                style: { width: 150 },
                render: (v, item) => Formatter.yesNo(v)
              },
              {
                dataIndex: "ownerRef",
                title: 'Owner',
                style: { width: 250 },
                render: (v) => {
                  if (!v) {
                    return null;
                  }
                  return (
                    <div
                      title={v}
                      style={{ maxWidth: 250, whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {v.email}<br/><small>{Formatter.fullName(v)}</small>
                    </div>
                  );
                },
              },
              { className: css.tdDate, dataIndex: "createdAt", title: "Created", render: Formatter.fullDate },
            ]}
          />
          {organizations?.pageInfo?.pageCount > 1 && (
            <PaginationFull
              style={{ marginTop: 20 }}
              onChangePageSize={(pageSize) => this.onChangePagination({ currentPage: 1, pageSize })}
              onChangeCurrentPage={(currentPage) => this.onChangePagination({ currentPage })}
              currentPage={currentPage}
              pageSize={pageSize}
              pageCount={organizations?.pageInfo?.pageCount}
            />
          )}
        </div>
      </>
    );
  }
}

AdminOrganizationList = (function withOrganizationReducer(WrappedComponent) {
  return function (props) {
    const notifications = useNotifications();
    return <WrappedComponent {...props} notifications={notifications} />;
  };
})(AdminOrganizationList);

AdminOrganizationList = withConfirm(AdminOrganizationList);
