import * as React from 'react';
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { Link } from "../../../DiscoverNew/UI/Link/Link";
import SectionTitle from "../../../SectionTitle/SectionTitle";

import css from './CandidateImport.module.css'
import { CandidateImportFooter } from "./CandidateImportFooter";
import { ImportSourceRadioCard } from "./ui/ImportSourceRadioCard";

type Props = {
  onSubmit: () => void
}

export const CandidateImportSource = (props: Props) => {
  return (
    <>
      <div className='max-width'>
        <div className={css.section}>
          <SectionTitle className={css.sectionTitle} level={2}>How would you like to start?</SectionTitle>
          <ImportSourceRadioCard style={{ marginBottom : 20 }} isActive title="Import file from computer"
                                 helpText="Upload a CSV file with contacts" />
          <ImportSourceRadioCard isDisabled isComingSoon title="Repeat a past import"
                                 helpText="Use a past import as a template for a new import with a new file" />
        </div>
        <div className={css.section}>
          <SectionTitle className={css.sectionTitle} level={2}>How to prepare your file?</SectionTitle>
          <ul className={css.list}>
            <li>A file must be a .csv file</li>
            <li>Have only one sheet</li>
            <li>Contacts: at least one of Email or Social Profile URL</li>
            <li>File size must be less than 50MB</li>
          </ul>
        </div>
        <div className={css.section}>
          <SectionTitle className={css.sectionTitle} level={2}>Need help getting started?</SectionTitle>
          <Link download='' component='a' href="https://app.chatterworks.com/assets/template.csv" className={css.downloadTemplateLink}><Icon name='fa-download' /> Download template spreadsheet</Link>
        </div>
      </div>
      <CandidateImportFooter onNext={props.onSubmit} />
    </>
  )
}