import * as api from "../../api/apiService";

export type CompanyInterface = {
  id: number;
  companyId: number;
  name: string;
  website: string;
  imgUrl?: string;
  createdBy?: {
    id: number;
  }
  projects: Array<{
    name: string;
    projectId: number;
  }>
};

export type FetchAllCompaniesArgs = {
  orderBy?: string;
  isAscending?: boolean;
}

type CreateCompanyInputDTO = {
  name: string
  website?: string
}

type UpdateCompanyInputDTO = {
  name: string
  website: string
}

class CompanyService {

  mapToInterface(obj: any): CompanyInterface {
    return {
      id: obj.id,
      companyId : obj.id,
      name : obj.name,
      imgUrl: obj.imgUrl,
      website : obj.website,
      projects : obj.projects,
      createdBy : obj.createdBy,
    };
  }


  fetchAll = async (args?: FetchAllCompaniesArgs): Promise<CompanyInterface[]> => {
    const result = await api.GetCompanies(args?.orderBy, args?.isAscending);
    if (!result.data) {
      return Promise.reject(result.error);
    }

    return result.data.map(this.mapToInterface);
  }

  fetchOne = async (id: number): Promise<CompanyInterface> => {
    const result = await api.GetCompany(id);
    if (!result.data) {
      return Promise.reject(result.error);
    }
    return this.mapToInterface(result.data);
  }

  create = async (dto: CreateCompanyInputDTO): Promise<CompanyInterface> => {
    const result = await api.CreateCompany(dto.name, dto.website);
    if (!result.data) {
      return Promise.reject(result.error);
    }

    return this.mapToInterface(result.data);
  }

  update = async (id: string, dto: UpdateCompanyInputDTO): Promise<CompanyInterface> => {
    const result = await api.UpdateCompany({ companyId: id, name :dto.name, website: dto.website });
    if (!result.data) {
      return Promise.reject(result.error);
    }

    return this.mapToInterface(result.data);
  }

}

export const companyService = new CompanyService();