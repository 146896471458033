import React from "react";
import { debounce } from "lodash";
import { useModalNavigator } from "../../contexts/modalNavigator";
import { useUserData } from "../../contexts/userContext";
import { ProjectsScreenHeader } from "./ProjectsScreenHeader/ProjectsScreenHeader";
import { ProjectCard } from "../../components/project/ProjectCard/ProjectCard";
import { projectService } from "./ProjectService";
import { PaginationFull } from "../../components/DiscoverNew/UI/PaginationFull/PaginationFull";
import { NoData } from "../../components/DiscoverNew/UI/NoData/NoData";
import { Link } from "../../components/DiscoverNew/UI/Link/Link";
import { useNotifications } from "../../components/DiscoverNew/contexts/Notification/notifications";
import { UrlHelper } from "../../components/DiscoverNew/util/UrlHelper";
import { omit, pick } from "../../components/DiscoverNew/util/util";
import { DEFAULT_PAGE_SIZE } from "../../constants/constants";
import { Loading } from "../../components/DiscoverNew/UI/Loading/Loading";
import { QUERY_NAME, queryRegistry } from "../../utils/queryRegistry";

import css from "./projects.module.css";
import { PermissionEnum, permissionService } from "./PermissionService";
import { projectInviteService } from "./ProjectInviteService";
import { ProjectInviteCard } from "../../components/project/ProjectCard/ProjectInviteCard/ProjectInviteCard";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { CreateProjectModal } from "../../components/modals/CreateProjectModal/CreateProjectModal";

const ownedByOptions = [
  { value: "all", label: "Mine & Shared" },
  { value: "mine", label: "Only Mine" },
  { value: "shared", label: "Only Shared" },
  { value: "organization", label: "Organization" },
];

const statusOptions = [
  { value: "all", label: "All" },
  { value: "active", label: "Active" },
  { value: "archived", label: "Archived" },
];

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAllInvitationsVisible: false,
      pageLoading: true,
      isCreateModalOpen: false,
      filters: this.getDefaultFilters(),
      pagination: this.getDefaultPagination(),
    };
  }

  getDefaultFilters() {
    return {
      query: "",
      orderBy: this.getOrderByOptions()[0],
      ownedBy: ownedByOptions[0],
      status: statusOptions[1],
    };
  }

  getDefaultPagination() {
    return {
      pageSize: DEFAULT_PAGE_SIZE,
      currentPage: 1,
    };
  }

  async componentDidMount() {
    queryRegistry.setQuery(QUERY_NAME.fetchProjects, this.fetchData);
    await this.restoreSearchFromUrl();
    try {
      await this.fetchData();
      this.setState({ pageLoading: false });
    } catch (err) {
      this.setState({ pageLoading: false });
    }
  }

  componentWillUnmount() {
    queryRegistry.deleteQuery(QUERY_NAME.fetchProjects);
  }

  restoreSearchFromUrl = () => {
    const data = UrlHelper.parseSearch(window.location.search);
    const { currentPage, pageSize, ...filters } = data || {};
    const defaultFilters = this.getDefaultFilters();
    const defaultPagination = this.getDefaultPagination();

    return new Promise((resolve) => {
      this.setState(
        {
          filters: {
            ...defaultFilters,
            ...pick(filters, Object.keys(defaultFilters)),
            orderBy: filters.orderByValue
              ? {
                  value: filters.orderByValue,
                  label: filters.orderByLabel,
                  dir: filters.orderByDir,
                }
              : defaultFilters.orderBy,
            ownedBy: filters.ownedByValue
              ? {
                  value: filters.ownedByValue,
                  label: filters.ownedByLabel,
                }
              : defaultFilters.ownedBy,
            status: filters.statusValue
              ? {
                  value: filters.statusValue,
                  label: filters.statusLabel,
                }
              : defaultFilters.status,
          },
          pagination: {
            currentPage: parseInt(currentPage || defaultPagination.currentPage),
            pageSize: parseInt(pageSize || defaultPagination.pageSize),
          },
        },
        resolve
      );
    });
  };

  saveSearchToUrl() {
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;
    const activeFilters = pick(filters, Object.keys(filters));
    const filterString = UrlHelper.stringifyParams({
      ...omit(activeFilters, ["orderBy", "ownedBy", "status"]),
      orderByValue: activeFilters.orderBy.value,
      orderByLabel: activeFilters.orderBy.label,
      orderByDir: activeFilters.orderBy.dir,
      ownedByValue: activeFilters.ownedBy.value,
      ownedByLabel: activeFilters.ownedBy.label,
      statusValue: activeFilters.status.value,
      statusLabel: activeFilters.status.label,
      pageSize,
      currentPage,
    });
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
  }

  debouncedSaveSearchToUrl = debounce(this.saveSearchToUrl, 200);

  fetchData = async () => {
    const { query, orderBy, ownedBy, status } = this.state.filters;
    const { pageSize, currentPage } = this.state.pagination;
    const params = {
      query,
      orderBy: orderBy.value,
      sortBy: orderBy.dir?.toLowerCase() || "",
      ownership: ownedBy.value === "all" ? undefined : ownedBy.value,
      archived: status.value,
      limit: pageSize,
      skip: (currentPage - 1) * pageSize,
    };

    try {
      this.setState({ pageLoading: true });
      const projectsData = await projectService.fetchProjects(params);
      let projectInvitationsData = [];
      try {
        projectInvitationsData = await projectInviteService.fetchMyIncomingInvites();
      } catch (err) {
        console.log("Failed to fetch project invites", err);
      }
      this.setState({ pageLoading: false, projectsData, projectInvitationsData });
    } catch (err) {
      this.setState({ pageLoading: false, projectsData: [] });
    }
  };

  debouncedFetchData = debounce(this.fetchData, 250);

  getOrderByOptions = () => {
    const { uiSettings } = this.props.userData.state.user;
    return projectService.getOrderByOptions(uiSettings);
  };

  onProjectCreated = async () => {
    const { notifications } = this.props;
    this.fetchData();
    this.setState({ isCreateModalOpen: false });
    notifications.showSuccess("Project has been created!");
    // When a company is created with a website url, the company image is
    // asynchronously retrieved and stored. Retrieve the projects after
    // allowing time for the company image to be stored.
    setTimeout(() => this.fetchData(), 4000);
  };

  onChangeFilter = (filter) => {
    if (filter.query) {
      // @todo analytics service
      window.analytics.track("Search", {
        query_string: filter.query,
        type: "project",
      });
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          ...filter,
        },
        pagination: {
          ...this.state.pagination,
          currentPage: 1,
        },
      },
      () => {
        if (filter.query) {
          this.debouncedFetchData();
          this.debouncedSaveSearchToUrl();
        } else {
          this.fetchData();
          this.saveSearchToUrl();
        }
      }
    );
  };

  onChangePagination = (pagination) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          ...pagination,
        },
      },
      () => {
        this.saveSearchToUrl();
        this.fetchData();
      }
    );
  };

  render() {
    const { uiSettings } = this.props.userData.state.user;
    const { isCreateModalOpen, projectsData, projectInvitationsData, pageLoading, isAllInvitationsVisible } = this.state;

    const { pageSize, currentPage } = this.state.pagination;
    const { query, orderBy, ownedBy, status } = this.state.filters;

    const projects = projectsData?.nodes || [];

    const canCreate = permissionService.can(null, PermissionEnum.projectCreate);

    return (
      <>
        <ProjectsScreenHeader
          projects={projects}
          total={projectsData?.pageInfo?.total || 0}
          orderBy={orderBy}
          setOrderBy={(orderBy) => this.onChangeFilter({ orderBy })}
          orderByOptions={this.getOrderByOptions()}
          status={status}
          setStatus={(status) => this.onChangeFilter({ status })}
          statusOptions={statusOptions}
          ownedBy={ownedBy}
          setOwnedBy={(ownedBy) => this.onChangeFilter({ ownedBy })}
          ownedByOptions={ownedByOptions}
          search={query}
          onSearchChange={(query) => this.onChangeFilter({ query })}
          onCreateProjectClick={() => this.setState({ isCreateModalOpen: true })}
        />
        <div className={css.container}>
          {pageLoading && <Loading />}
          {!pageLoading && !!projectInvitationsData?.length && (
            <>
              <div className={css.listHeader}>
                <SectionTitle level={2}>Invitations</SectionTitle>
                <hr className={css.divider} />
              </div>
              <div className={css.invitationsList}>
                {projectInvitationsData.slice(0, isAllInvitationsVisible ? projectInvitationsData.length : 5).map((invite, index) => {
                  return (
                    <ProjectInviteCard key={invite.id} data={invite} onUpdated={this.fetchData} />
                  );
                })}
              </div>
              {projectInvitationsData.length > 5 && (
                <div style={{ marginTop: 12, paddingLeft: 23 }}>
                  <Link onClick={() => this.setState({ isAllInvitationsVisible: !isAllInvitationsVisible })}>
                    {isAllInvitationsVisible ? "Show less" : `Show all ${projectInvitationsData.length} invitations`}
                  </Link>
                </div>
              )}
            </>
          )}
          {!pageLoading && (
            <>
              {!!projectInvitationsData.length && (
                <div style={{ paddingTop: 60 }}>
                  <SectionTitle level={2}>Projects</SectionTitle>
                  <hr className={css.divider} />
                </div>
              )}
              {projects?.map((project, index) => {
                return (
                  <ProjectCard key={project.projectId} className={css.item} data={project} onUpdated={this.fetchData} />
                );
              })}
            </>
          )}
          {!!query && !projects?.length && !pageLoading && (
            <NoData text={`No ${uiSettings?.mappings?.project} found for your request`} />
          )}
          {(!projects?.length && !query && !pageLoading) ? canCreate ? (
            <p className="text-center mt-5">
              Click{" "}
              <Link style={{ fontSize: "inherit" }} onClick={() => this.setState({ isCreateModalOpen: true })}>
                Add {uiSettings?.mappings?.project}
              </Link>{" "}
              to get started.
            </p>
          ) : <p className="text-center mt-5">You don't have any projects</p> : null}
          {projectsData?.pageInfo?.pageCount > 1 && !pageLoading && (
            <PaginationFull
              className={css.pagination}
              onChangePageSize={(pageSize) => this.onChangePagination({ currentPage: 1, pageSize })}
              onChangeCurrentPage={(currentPage) => this.onChangePagination({ currentPage })}
              currentPage={currentPage}
              pageSize={pageSize}
              pageCount={projectsData?.pageInfo?.pageCount}
            />
          )}
        </div>
        {isCreateModalOpen && (
          <CreateProjectModal
            onCreated={this.onProjectCreated}
            onClose={() => this.setState({ isCreateModalOpen: false })}
          />
        )}
      </>
    );
  }
}

Projects = (function withUserReducer(WrappedComponent) {
  return function (props) {
    const notifications = useNotifications();
    const modalNavigator = useModalNavigator();
    const userData = useUserData();
    return (
      <WrappedComponent {...props} userData={userData} modalNavigator={modalNavigator} notifications={notifications} />
    );
  };
})(Projects);

export default Projects;
