import * as React from "react";
import { useAuthController } from "../../../../contexts/AuthProvider";
import { useForm } from "../../../../hooks/useForm";
import {
  StageFlowItemInterface,
  stageFlowService,
} from "../../../../screens/profile/ProfileStageFlow/StageFlowService";
import { logger } from "../../../../services/Logger";
import { FormField } from "../../../DiscoverNew/Form/FormField/FormField";
import { Input } from "../../../DiscoverNew/Form/Input/Input";
import { Radio } from "../../../DiscoverNew/Form/Radio/Radio";
import { Select } from "../../../DiscoverNew/Form/Select/Select";
import { Button } from "../../../DiscoverNew/UI/Button/Button";
import { CreateProjectModalContext } from "../CreateProjectModal";
import { InterviewProcessStagesEditor } from "../InterviewProcessStagesEditor";

type FormValues = {
  name: string
  stages: StageFlowItemInterface[]
}

type Props = {
  interviewProcessOptions?: { label: string, value: string }[]
  onSubmit: (interviewProcessId: number) => void
}

enum InterviewProcessMode {
  Suggested = "suggested",
  Existing = "existing"
}

export const CreateInterviewProcessForm = (props: Props) => {
  const { loading : createProjectLoading } = React.useContext(CreateProjectModalContext);
  const { values, errors, setErrors, onChangeValues } = useForm<FormValues>({
    name : "",
    stages : stageFlowService.getSuggestedStages(),
  });
  const [mode, setMode] = React.useState<InterviewProcessMode>(InterviewProcessMode.Suggested);
  const [formLoading, setFormLoading] = React.useState(false);
  const user = useAuthController().user as any;

  const onChangeInterviewProcessToCopy = async (option: { value: string, label: string }) => {
    const ip = await stageFlowService.fetchOne(user.organizationId, parseInt(option.value));
    onChangeValues({ stages : ip.stages });
  };

  const onChangeMode = (mode: InterviewProcessMode) => {
    if (mode === InterviewProcessMode.Suggested) {
      onChangeValues({ stages : stageFlowService.getSuggestedStages() });
    } else {
      onChangeValues({ stages : [] });
    }
    setMode(mode);
  };

  const onCreate = async () => {
    if (!values.name) {
      setErrors({ name : "Name is required" });
      return;
    }
    if (mode === InterviewProcessMode.Existing && values.stages.length === 0) {
      setErrors({ stages : "Select an interview process" });
      return;
    }
    setFormLoading(true);
    try {
      const stageFlowId = await stageFlowService.create(user.organizationId, {
        name : values.name,
        stages : values.stages.map(((stage, i) => ({
          ...stage,
          order : i + 1,
        }))),
      });
      props.onSubmit(stageFlowId);
    } catch (err) {
      logger.error("Failed to create interview process", err);
      setErrors({ name : "Failed to create interview process" });
    } finally {
      setFormLoading(false);
    }
  };

  const loading = createProjectLoading || formLoading;

  return (
    <form>
      <div style={{ display : "flex", flexDirection : "column", gap : 16, paddingTop : 12 }}>
        <h4 style={{ fontSize : "1.1rem" }}>Create a new Interview Process</h4>
        <FormField label="Name" required error={errors.name}>
          <Input placeholder="Enter Interview Process Name" value={values.name}
                 onChange={e => onChangeValues({ name : e.target.value })} />
        </FormField>
        {props.interviewProcessOptions?.length && (
          <div>
            <p>Choose how you would like to set up your interview stages:</p>
            <div style={{ display : "flex" }}>
              <div style={{ display : "flex", flex : 1 }}>
                <Radio id={InterviewProcessMode.Suggested} checked={mode === InterviewProcessMode.Suggested}
                       onChange={() => onChangeMode(InterviewProcessMode.Suggested)} />
                <FormField.Label htmlFor="suggested" style={{ marginLeft : 10, cursor : "pointer" }}>
                  Use suggested stages
                </FormField.Label>
              </div>
              <div style={{ display : "flex", flex : 1 }}>
                <Radio id={InterviewProcessMode.Existing} checked={mode === InterviewProcessMode.Existing}
                       onChange={() => onChangeMode(InterviewProcessMode.Existing)} />
                <FormField.Label htmlFor="existing" style={{ marginLeft : 10, cursor : "pointer" }}>
                  Create from existing
                </FormField.Label>
              </div>
            </div>
          </div>
        )}
        {mode === InterviewProcessMode.Existing ? (
          <>
            <FormField label="Interview Process" required error={errors.stages}>
              {/* @ts-ignore todo */}
              <Select options={props.interviewProcessOptions} error={errors.stages} onChange={onChangeInterviewProcessToCopy} />
            </FormField>
            {values.stages.length > 0 && (
              <InterviewProcessStagesEditor onChange={v => onChangeValues({ stages : v })} value={values.stages} />
            )}
          </>
        ) : (
          <div>
            <b>Interview Stages</b>
            <p style={{ margin: '10px 0' }}>
              You can rename, change order, add or remove stages.
              The process you create will be saved to My Account &gt; Interview Processes.
            </p>
            <InterviewProcessStagesEditor onChange={v => onChangeValues({ stages : v })} value={values.stages} />
          </div>
        )}
        <Button style={{ marginTop : 12 }} onClick={onCreate} disabled={loading}>
          {loading ? "Creating..." : "Apply and Create Project"}
        </Button>
      </div>
    </form>
  );
};