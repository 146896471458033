import cn from "classnames";
import * as React from "react";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Link } from "../../DiscoverNew/UI/Link/Link";

import css from "./UploadFileCard.module.css";

type Props = {
  error?: boolean
  value: File | undefined,
  onChange: (value: File | undefined) => void
}

export const UploadFileCard = (props: Props) => {
  const { value, onChange } = props;
  return (
    <div className={cn(css.holder, { [css.error] : props.error })}>
      {!value && <input className={css.fileInput} type="file" onChange={e => onChange(e.target.files?.[0] || undefined)} />}
      {value ? (
          <div className={css.selectedFileContainer} style={{ justifyContent : "space-between" }}>
            <div className={css.row} style={{ gap : 16 }}>
              <Icon className={css.faFile} name="fa-file" />
              {value.name}
            </div>
            <Icon className={css.faTrash} name="fa-trash" onClick={() => onChange(undefined)} />
          </div>
        )
        : <div className={css.placeholderContainer}><Link>Choose a file</Link>&nbsp;or drag and drop here</div>
      }
    </div>
  );
};