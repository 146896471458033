import cn from "classnames";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";
import { Button } from "../../../DiscoverNew/UI/Button/Button";
import { Link } from "../../../DiscoverNew/UI/Link/Link";
import { withConfirm } from "../../../hoc/withConfirm";

import css from "./CandidateImport.module.css";
import { CandidateImportContext } from "./ui/CandidateImportContext";

type Props = {
  onNext: () => void,
  onBack?: () => void,
  children?: React.ReactNode
  nextButtonDisabled?: boolean
  nextButtonTitle?: string
}

// @ts-ignore
export const CandidateImportFooter: React.FC<Props> = withConfirm((props: any) => {
  const ctx = React.useContext(CandidateImportContext);
  const history = useHistory();

  const onCancel = async () => {
    // @ts-ignore
    const isConfirmed = await props.confirm.open({
      title : "Accept Invitation",
      content : <>Are you sure you want to cancel the import?</>,
      cancelButtonTitle: 'Dismiss',
      confirmButtonTitle: 'Cancel Import'
    });
    if (!isConfirmed) {
      return;
    }

    history.push(ROUTES.projects);
  }
  return (
    <footer className={css.footer}>
      <div className={cn(css.footerInner, 'max-width')}>
        <div className={css.row}>
          {ctx.step > 1 && (
            <Button className={css.navButton} onClick={() => ctx.setStep(ctx.step - 1)}>Back</Button>
          )}
          <Link onClick={onCancel}>Cancel Import</Link>
        </div>
        <div className={css.row}>
          {props.children}
          <Button className={css.navButton} onClick={props.onNext} disabled={props.nextButtonDisabled}>{props.nextButtonTitle || 'Next'}</Button>
        </div>
      </div>
    </footer>
  )
})