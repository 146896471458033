import * as React from "react";
import { useAuthController } from "../../../contexts/AuthProvider";
import { useForm } from "../../../hooks/useForm";
import { StageFlowItemInterface } from "../../../screens/profile/ProfileStageFlow/StageFlowService";
import { FormField } from "../../DiscoverNew/Form/FormField/FormField";
import { Select } from "../../DiscoverNew/Form/Select/Select";
import { Button } from "../../DiscoverNew/UI/Button/Button";
import { Icon } from "../../DiscoverNew/UI/IconComponent/Icon";
import { Link } from "../../DiscoverNew/UI/Link/Link";
import { BackButton } from "../../ui/BackButton/BackButton";
import { CreateInterviewProcessForm } from "./CreateInterviewProcessForm/CreateInterviewProcessForm";
import { CreateProjectModalContext } from "./CreateProjectModal";

type Props = {
  interviewProcessOptions: { label: string, value: string }[]
  onCreate: (interviewProcessId: string) => void
}

type FormValues = {
  interviewProcess?: { value: string, label: string }
}


export function CreateProjectFormInterviewProcessHasProcesses(props: Props) {

  const { loading } = React.useContext(CreateProjectModalContext);
  const [displayCreateForm, setDisplayCreateForm] = React.useState(false);
  const { values, errors, setErrors, onChangeValues} = useForm<FormValues>({ interviewProcess: undefined });

  const onCreate = () => {
    if (!values.interviewProcess) {
      setErrors({ interviewProcess: "This field is required" });
      return;
    }
    props.onCreate(values.interviewProcess!.value);
  }

  if (displayCreateForm) {
    return <>
      <div>
        <BackButton onClick={() => setDisplayCreateForm(false)} />
      </div>
      <CreateInterviewProcessForm
        onSubmit={v => props.onCreate(v.toString())}
        interviewProcessOptions={props.interviewProcessOptions}
      />
    </>
  }

  return (
    <div>
      <FormField label="Interview Process" required error={errors.interviewProcess}>
        {/* @ts-ignore todo */}
        <Select options={props.interviewProcessOptions} onChange={v => onChangeValues({ interviewProcess : v })} error={errors.interviewProcess} />
      </FormField>
      <div style={{ marginTop : 3 }}>
        <Link leftIcon={<Icon name="plus" />} onClick={() => setDisplayCreateForm(true)}>
          Create new interview process
        </Link>
      </div>
      <footer style={{ paddingTop : 30, display : 'flex', gap : 16 }}>
        <Button onClick={onCreate} disabled={loading} style={{ flex : 1 }}>
          {loading ? "Creating..." : "Create Project"}
        </Button>
      </footer>
    </div>
  );
}