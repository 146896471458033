import { useState, useEffect } from "react";
import { PermissionEnum, permissionService } from "../screens/projects/PermissionService";
import { projectService } from "../screens/projects/ProjectService";

export const useCompaniesWithProjects = () => {
  const [options, setOptions] = useState<any[]>([]);

  async function fetchOptions() {
    const ownership = permissionService.can(null, PermissionEnum.projectCanFetchOrganization) ? 'organization' : undefined;
    const projects = await projectService.fetchProjects({ limit : 200, ownership });
    const companiesWithProjects = projects.nodes.reduce((acc, item) => {
      if (acc[item.company.id]) {
        acc[item.company.id].options.push({ value : item.id, label : item.name });
      } else {
        acc[item.company.id] = {
          label : item.company.name,
          options : [{ value : item.id, label : item.name }],
        };
      }
      return acc;
    }, {} as Record<string, any>);
    let companiesWithProjectsSorted = Object.values(companiesWithProjects).sort((a, b) => a.label?.localeCompare(b.label));
    companiesWithProjectsSorted = companiesWithProjectsSorted.map((company: any) => {
      company.options = company.options.sort((a: any, b: any) => a.label.localeCompare(b.label));
      return company;
    });
    setOptions(companiesWithProjectsSorted);
  }

  useEffect(() => {
    fetchOptions();
  }, [])

  return options;
}