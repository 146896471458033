import { cx } from "emotion";
import * as React from "react";
import { Button } from "../Button/Button";
import { ButtonHTML } from "../ButtonHTML/ButtonHTML";
import { Dropdown } from "../Dropdown/Dropdown";
import { Icon } from "../IconComponent/Icon";

import css from "./ContextMenuDropdown.module.css";

type Props = {
  children: React.ReactNode
  label?: React.ReactNode
  contentStyle?: React.CSSProperties
}

export const ContextMenuDropdown = (props: Props) => {
  return (
    <Dropdown
      classNames={{ content : css.content }}
      contentStyle={props.contentStyle}
      label={props.label || <ButtonHTML className={css.label}><Icon name="dots-vertical-small" /></ButtonHTML>}
    >
      {props.children}
    </Dropdown>
  );
};

ContextMenuDropdown.ActionButton = (props: any) => {
  return (
    <Dropdown.Context.Consumer>
      {(value: any) => {
        // @ts-ignore
        return (
          <Button
            style={{ marginLeft: 16 }}
            disabled={props.disabled}
            theme="outline"
            rightIcon={
              value.opened ? (
                <span style={{ transform: "rotate(180deg)", display: "inline-block" }}>
                          <Icon name="chevron-thin" />
                        </span>
              ) : (
                <Icon name="chevron-thin" />
              )
            }
          >
            Actions
          </Button>
        );
      }}
    </Dropdown.Context.Consumer>
  );
};

ContextMenuDropdown.Item = (props: any) => {
  const { className, icon, iconStyle, children, ...rest } = props;
  return (
    <Dropdown.Item className={cx(className, css.item)} {...rest}>
      {icon ? <Icon name={icon} className={css.icon} iconStyle={iconStyle} /> : null}
      {children}
    </Dropdown.Item>
  );
};

ContextMenuDropdown.DangerItem = (props: any) => {
  const { className, icon, children, ...rest } = props;
  return (
    <Dropdown.Item className={cx(className, css.itemDanger)} {...rest}>
      {icon ? <Icon name={icon} className={css.icon} /> : null}
      {children}
    </Dropdown.Item>
  );
};