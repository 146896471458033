import React, { useEffect } from "react";
import { cx } from "emotion";
import { NavLink, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import RouteSwitch from "../../../components/CustomRouter/RouteSwitch";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import { Tabs } from "../../../components/ui/Tabs/Tabs";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";

import styles from "./ProfileBilling.module.css";
import { useAuthController } from "../../../contexts/AuthProvider";

const tabList = [
  { path: "plans", title: "Plans & Payment", permissions: ['organization::owner', 'organization::super-admin'] },
  { path: "history", title: "Billing History", permissions: ['organization::owner', 'organization::super-admin'] },
];

const ProfileBilling = ({ routes }) => {
  const authController = useAuthController();
  const { tab: activeTab } = useParams();

  useEffect(() => {
    return injectStyleOverride();
  }, []);

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <SectionTitle className={styles.title} level={2}>
          Billing
        </SectionTitle>
        <Tabs className={styles.tabs}>
          {tabList
            .filter(item => {
              return !item.permissions.length || item.permissions.some(p => authController.user.seat.permissions.includes(p));
            })
            .map((tab) => (
            <Tabs.Tab
              key={tab.path}
              className={cx(styles.tab, { [styles.active]: activeTab === tab.path })}
              component={NavLink}
              to={tab.path}
            >
              {tab.title}
            </Tabs.Tab>
          ))}
        </Tabs>
      </div>
      <div className={styles.tabContent}>
        <RouteSwitch routes={routes} />
      </div>
    </div>
  );
};

ProfileBilling.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      Component: PropTypes.elementType,
      exact: PropTypes.bool,
      type: PropTypes.string,
      routes: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default ProfileBilling;
