import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { debounce } from "lodash";

import CandidateDetailModalWrapper from "../../../components/modals/CandidateDetailModalWrapper";
import { DiscoverFilters } from "./DiscoverFilters/DiscoverFilters";
import { DiscoverSearchResults } from "./DiscoverSearchResults/DiscoverSearchResults";
import { omit, replaceSmartQuotes, shortId, withoutEmptyKeys } from "../util/util";
import { DiscoverSearchHelper } from "../helpers/DiscoverSearchHelper";
import * as api from "../../../api/apiService";
import { UrlHelper } from "../util/UrlHelper";
import { withNotifications } from "../contexts/Notification/notifications";
import { useUserData } from "../../../contexts/userContext";
import { DiscoverSearchLocationHelper } from "../helpers/location/DiscoverSearchLocationHelper";
import {
  COMPANY_NAME_DEFAULT_OPTION,
  DEFAULT_DISTANCE_OPTION,
  DEFAULT_PAGE_SIZE,
  DISCOVER_DEFAULT_COUNTRY_CODE,
  DISCOVER_DEFAULT_COUNTRY_OPTION,
  DISCOVER_DEFAULT_STATE_OPTION,
  DISCOVER_SIDEBAR_SIZE,
  IMPORTANT_SOCIAL_PROFILES,
  LOCATION_SEARCH_MODE,
  SKILLS_MODE_DEFAULT_OPTION,
  TITLE_MODE_DEFAULT_OPTION,
} from "../../../constants/constants";
import { elasticSearchService } from "../services/ElasticSearchService";

import css from "./DiscoverNew.module.css";
import { discoverSearchService } from "../services/DiscoverSearchService";
import { candidateService } from "../../../screens/projects/CandidateService";
import InsufficientCreditsModal from "../../modals/InsufficientCreditsModal/InsufficientCreditsModal";

export const getDefaultAddressFilter = () => {
  return {
    id: shortId(),
    city: null, // { name: string, lat: float, lng: float }
    state: DISCOVER_DEFAULT_STATE_OPTION,
    zip: "",
    locationSearchMode: LOCATION_SEARCH_MODE.zip,
    distance: DEFAULT_DISTANCE_OPTION.value,
  };
};

const getDefaultFilters = () => {
  return {
    title: "",
    titleMode: TITLE_MODE_DEFAULT_OPTION.value, // @todo
    companyNameMode:  COMPANY_NAME_DEFAULT_OPTION.value,
    employer: "",
    country: DISCOVER_DEFAULT_COUNTRY_OPTION,
    addresses: [
      // @todo
      getDefaultAddressFilter(),
    ],
    withEmailsOnly: false,
    withPhonesOnly: false,
    minYears: "",
    maxYears: "",
    firstName: "",
    lastName: "",
    school: "",
    degree: [],
    // ethnicities: [], // @todo
    major: [],
    industries: [],
    social: IMPORTANT_SOCIAL_PROFILES,
    excludeViewed: false,
    customIndex: "",
    currentCompanySize: [],
    skillsMode: SKILLS_MODE_DEFAULT_OPTION.value,
    skills: "",
    skillsShould: "",
    skillsMustSelect: [],
    skillsShouldSelect: [],
  };
};

const defaultFiltersJSON = JSON.stringify(getDefaultFilters());

const getDefaultPagination = () => {
  return {
    pageSize: DEFAULT_PAGE_SIZE,
    currentPage: 1,
  };
};

let lastFilterString = "";

class DiscoverNew extends React.Component {
  state = {
    filters: getDefaultFilters(),
    pagination: getDefaultPagination(),

    sidebarSize: DISCOVER_SIDEBAR_SIZE.default,

    selected: {},

    savedSearches: [],
    recentSavedSearches: [],

    result: undefined,
    error: undefined,
    loading: false,

    candidateModalData: undefined,
    showInsufficientCreditsModal: false
  };

  componentDidMount() {
    window.addEventListener("popstate", this.onNavigate);
    if (window.location.search.includes('first-purchase-success')) {
      this.props.notificationController.showSuccess("Thank you for your purchase! You've successfully activated your subscription.");
      window.history.replaceState({}, "", window.location.pathname);
    } else if (window.location.search.length > 1) {
      this.restoreSearchFromUrl();
    } else {
      this.restoreSearchFromUrl(lastFilterString);
    }
    this.fetchSavedSearches();
    const { candidateId } = this.props.routerParams;
    if (candidateId) {
      this.setCandidate(candidateId);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onNavigate);
  }

  onNavigate = () => {
    this.restoreSearchFromUrl();
    if (this.state.candidateModalData) {
      this.setState({ candidateModalData: undefined });
    }
  };

  setCandidate(candidateIdParam) {
    if (!candidateIdParam) {
      return;
    }
    // GetCandidate API is called using the candidateId in child component
    const candidate = { candidateId: candidateIdParam };
    this.setState({ candidateModalData: { candidate: candidate, initialPage: "detail" } });
  }

  restoreSearchFromUrl = (filterString) => {
    let data = null;
    if (filterString) {
      data = UrlHelper.parseSearch(filterString);
      window.history.replaceState({}, "", window.location.pathname + "?" + lastFilterString);
    } else {
      lastFilterString = window.location.search.slice(1);
      data = UrlHelper.parseSearch(lastFilterString);
    }
    if (data && Object.keys(data).length) {
      const defaultFilters = getDefaultFilters();
      const defaultPagination = getDefaultPagination();
      const { pagination, filters } = DiscoverSearchHelper.deserializeSearchQueryStringToObject(
        data,
        defaultFilters,
        defaultPagination
      );
      this.setState(
        {
          filters,
          pagination,
        },
        this.performSearch
      );
    }
  };

  saveSearchToUrl() {
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;
    const activeFilters = withoutEmptyKeys(filters);
    const filterString = DiscoverSearchHelper.serializeSearchToQueryString(activeFilters, pageSize, currentPage);
    this.trackSearchQuery(activeFilters, filterString);
    window.history.pushState({}, "", window.location.pathname + "?" + filterString);
    lastFilterString = filterString;
  }

  debouncedSaveSearch = debounce(this.saveSearchToUrl, 300);

  performSearch = async (params) => {
    // @todo error handling
    const {
      pagination: { pageSize, currentPage },
      filters,
    } = this.state;

    const offset = pageSize * (currentPage - 1);
    // @todo
    const queryData = {
      ...filters,
      titleMode: filters.titleMode,
      companyNameMode: filters.companyNameMode,
      country: filters.country?.value ? elasticSearchService.getCountrySearchTerm(filters.country.value) : undefined,
      skillsMode: !!window.searchPillBoxes ? filters.skillsMode : undefined,
      skillsMustSelect: !!window.searchPillBoxes ? filters.skillsMustSelect : undefined,
      skillsShouldSelect: !!window.searchPillBoxes ? filters.skillsShouldSelect : undefined,
      addresses: filters.addresses.map((item) => {
        return {
          ...item,
          state: item.state?.value || undefined,
        };
      }),
    };

    const query = elasticSearchService.makeQuery(queryData, { limit: pageSize, offset });

    try {
      if (!params || params.showLoader !== false) {
        this.setState({ loading: true });
      }
      let options = {
        excludeViewed: filters.excludeViewed
      }
      if ((window.devmode || window.indextest) && filters.customIndex && filters.customIndex.trim() !== "") {
        options.customCandidateIndex = filters.customIndex.trim().split(".")[0];
      }
      const data = await discoverSearchService.fetchCandidates(query, options);

      if (window.devmode) {
        window.lastSearchResult = data.items;
      }

      let cappedTotal = data.pageInfo.total > 10000 ? 10000 : data.pageInfo.total;
      this.setState({
        loading: false,
        result: {
          data: data.items,
          pageInfo: {
            count: data.pageInfo.total,
            total: cappedTotal,
            pageCount: Math.max(Math.ceil(cappedTotal / pageSize), 1),
          },
        },
      });
    } catch (error) {
      console.error(error);
      this.setState({
        loading: false,
        error,
      });
    }
  };

  trackSearchQuery = (filters, filtersString) => {
    const search = {
      type: "candidate",
    };
    if (filtersString.length > 0) {
      search.query = `?${filtersString}`;
    }

    if (filters?.country?.value) {
      search.country = filters.country.value;
    }

    search.addresses = filters.addresses.map((item) => withoutEmptyKeys(item));

    if (filters?.employer) {
      search.employer = filters.employer;
    }

    if (filters?.title) {
      search.title = filters.title;
    }

    if (filters?.school) {
      search.school = filters.school;
    }

    if (filters?.degree?.length > 0) {
      search.degrees = filters.degree;
    }

    if (filters?.currentCompanySize?.length > 0) {
      search.current_company_size = filters.currentCompanySize;
    }
    
    if (filters?.skills) {
      search.skills = filters.skills;
    }

    if (filters?.skillsShould) {
      search.skills_should = filters.skillsShould;
    }

    if (filters?.skillsMustSelect?.length > 0) {
      search.skills_must_select = filters.skillsMustSelect;
    }

    if (filters?.skillsShouldSelect?.length > 0) {
      search.skills_should_select = filters.skillsShouldSelect;
    }

    if (filters?.major?.length > 0) {
      search.majors = filters.major;
    }


    if (filters?.name) {
      search.name = filters.name;
    }

    if (filters?.social) {
      search.social_sites = filters.social;
    }

    window.analytics.track("Discover New Search", search);
  };

  debouncedPerformSearch = debounce(this.performSearch, 300);

  /**
   * @param {Record<string, { id : string }>} value
   */
  onChangeSelection = (value) => {
    const { selected } = this.state;
    if (selected[value.id]) {
      this.setState({ selected: omit(selected, [value.id]) });
    } else {
      this.setState({
        selected: {
          ...selected,
          [value.id]: value,
        },
      });
    }
  };

  onSelectAll = () => {
    this.setState({
      selected: this.state.result.data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
    });
  };

  onClearSelection = () => {
    this.setState({
      selected: {},
    });
  };

  onResetSearch = () => {
    this.setState({
      filters: getDefaultFilters(),
      pagination: getDefaultPagination(),
      selected: {},
      result: undefined,
      error: undefined,
      loading: false,
    });
    window.history.pushState({}, "", window.location.pathname);
    lastFilterString = "";
  };

  onSaveSearch = async ({ name }) => {
    const { notificationController } = this.props;
    const { filters } = this.state;
    if (!name || !name.trim()) return;

    const fields = {
      degree: filters.degree,
      employer: filters.employer,
      first_name: filters.firstName,
      company_name_mode: filters.companyNameMode,
      last_name: filters.lastName,
      country: filters.country.value || null,
      addresses: filters.addresses.map((item) => {
        return {
          ...item,
          state: item?.state?.value ?? item?.state ?? null,
        };
      }),
      majors: filters.major,
      school: filters.school,
      social: filters.social,
      title: filters.title,
      title_mode: filters.titleMode,
      min_years: filters.minYears ? parseInt(filters.minYears) : null,
      max_years: filters.maxYears ? parseInt(filters.maxYears) : null,
      ethnicities: filters.ethnicities,
      industries: filters.industries,
      with_phones_only: filters.withPhonesOnly,
      with_emails_only: filters.withEmailsOnly,
      current_company_size: filters.currentCompanySize,
      skills_mode: filters.skillsMode,
      skills: filters.skills,
      skills_should: filters.skillsShould,
      skills_must_select: filters.skillsMustSelect,
      skills_should_select: filters.skillsShouldSelect,
    };

    try {
      await api.CreateSavedSearch({
        name: name.trim(),
        fields: fields,
      });
      notificationController.showSuccess("Search has been saved!");
      this.fetchSavedSearches();
    } catch (err) {
      console.error("onSaveSearch", err);
    }
  };

  fetchSavedSearches = async () => {
    try {
      const savedSearches = await api.GetSavedSearches();
      const recentSavedSearches = await api.GetSavedSearches("most_recent", false, 3);

      const defaultFilters = getDefaultFilters();

      function getStateForSavedSearch(country, state) {
        if (state) {
          const countryCode = country || DISCOVER_DEFAULT_COUNTRY_CODE;
          const option = DiscoverSearchLocationHelper.getStateOptionsForCountry(countryCode).find(
            (item) => item.value === state
          );
          return option || defaultFilters.state;
        }
        return defaultFilters.state;
      }

      function getCountryForSavedSearch(search) {
        const { fields } = search;
        if (fields.country) {
          const option = DiscoverSearchLocationHelper.getCountryOptions().find((item) => item.value === fields.country);
          return option || defaultFilters.country;
        }
        return defaultFilters.country;
      }

      function processSavedSearch(search) {
        const { fields, savedSearchId, name, createdAt } = search;
        return {
          id: savedSearchId,
          name: name,
          createdAt: createdAt,
          fields: {
            _source: fields,

            employer: fields.employer || defaultFilters.employer,
            country: getCountryForSavedSearch(search),
            addresses: fields.addresses?.map((item) => {
              let locationSearchMode = LOCATION_SEARCH_MODE.zip;
              if (item.state) {
                locationSearchMode = LOCATION_SEARCH_MODE.state;
              }
              if (item.city?.name) {
                locationSearchMode = LOCATION_SEARCH_MODE.city;
              }
              return {
                ...item,
                locationSearchMode,
                id: shortId(),
                state: getStateForSavedSearch(fields.country, item.state),
              };
            }),
            firstName: fields.first_name || defaultFilters.firstName,
            lastName: fields.last_name || defaultFilters.lastName,
            school: fields.school || defaultFilters.school,
            degree: fields.degree || defaultFilters.degree,
            major: fields.majors || defaultFilters.major,
            social: fields.social || defaultFilters.social,
            title: fields.title || defaultFilters.title,
            titleMode: fields.title_mode || defaultFilters.titleMode,
            companyNameMode: fields.company_name_mode || defaultFilters.companyNameMode,
            minYears: fields.min_years
            ? parseInt(fields.min_years) || defaultFilters.minYears
            : defaultFilters.minYears,
            maxYears: fields.max_years
            ? parseInt(fields.max_years) || defaultFilters.maxYears
            : defaultFilters.maxYears,
            currentCompanySize: fields.current_company_size || defaultFilters.currentCompanySize,
            skillsMode: fields.skills_mode || defaultFilters.skillsMode,
            skills: fields.skills || defaultFilters.skills,
            skillsShould: fields.skills_should || defaultFilters.skillsShould,
            skillsMustSelect: fields.skills_must_select || defaultFilters.skillsMustSelect,
            skillsShouldSelect: fields.skills_should_select || defaultFilters.skillsShouldSelect,
            withPhonesOnly: fields.with_phones_only || defaultFilters.withPhonesOnly,
            withEmailsOnly: fields.with_emails_only || defaultFilters.withEmailsOnly,
            ethnicities: fields.ethnicities || defaultFilters.ethnicities,
            industries: fields.industries || defaultFilters.industries,
          },
        };
      }

      this.setState({
        savedSearches: savedSearches.data.map(processSavedSearch),
        recentSavedSearches: recentSavedSearches.data.map(processSavedSearch),
      });
    } catch (err) {
      console.error("fetchSavedSearches", err);
    }
  };

  onDeleteSavedSearch = async (search) => {
    try {
      await api.DeleteSavedSearch(search.id);
      this.fetchSavedSearches();
    } catch (err) {
      console.error("onDeleteSavedSearch", err);
    }
  };

  onRestoreSavedSearch = (search) => {
    this.onChangeFilter(omit(search.fields, ["_source"]));
  };

  /**
   * @param {Record<string, any>} value
   */
  onChangeFilter = (value) => {
    const nextValue = { ...value };

    if (nextValue.country && Object.keys(nextValue).length === 1) {
      const defaultFilters = getDefaultFilters();
      nextValue.addresses = defaultFilters.addresses;
    }

    if (nextValue.skills) {
      nextValue.skills = replaceSmartQuotes(nextValue.skills);
    }
    if (nextValue.title) {
      nextValue.title = replaceSmartQuotes(nextValue.title);
    }
    const nextState = {
      filters: {
        ...this.state.filters,
        ...nextValue,
      },
      pagination: {
        ...this.state.pagination,
        currentPage: 1,
      },
    };

    if (JSON.stringify(nextState.filters) === defaultFiltersJSON) {
      this.onResetSearch();
    } else {
      this.setState(nextState, () => {
        this.debouncedSaveSearch();
        this.debouncedPerformSearch();
      });
    }
  };

  /**
   * @param {string} value
   */
  onChangeSidebarSize = (value) => {
    this.setState({
      sidebarSize: value,
    });
  };

  /**
   * @param {string} value
   */
  onChangeCurrentPage = (value) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          currentPage: parseInt(value),
        },
      },
      () => {
        this.saveSearchToUrl();
        this.performSearch();
      }
    );
  };

  /**
   * @param {string} value
   */
  onChangePageSize = (value) => {
    this.setState(
      {
        pagination: {
          currentPage: 1,
          pageSize: parseInt(value),
        },
      },
      () => {
        this.saveSearchToUrl();
        this.performSearch();
      }
    );
  };

  updateResultItem = (data) => {
    this.setState((prevState) => ({
      result: {
        ...prevState?.result,
        data: prevState?.result?.data?.map((item) => {
          if (item.id !== data.id) {
            return item;
          }
          return {
            ...item,
            ...data,
          };
        }),
      },
    }));
  };

  onUnlockCandidate = async (candidate) => {
    const { notificationController, userData } = this.props;
    if (userData.state.userCredits <= 0) {
      this.setState({ showInsufficientCreditsModal: true });
      return;
    }

    try {
      const response = await candidateService.unlockCandidate(candidate.id);
      if (response.status === 204) {
        // @todo AnalyticsService
        window.analytics.track("Candidate Unlocked", {
          candidate_id: candidate._source?.candidateId,
          state: candidate._source?.location?.region,
          locality: candidate._source?.location?.locality,
          is_unlocked: candidate._source?.unlockedAt,
        });
      }
      const { data } = await api.GetCandidate(candidate.id);
      const parsedContactData = discoverSearchService.parseCandidateContactData({
        phoneNumbers: data.phoneNumbers,
        emails: data.emails,
      })
      this.updateResultItem({
        ...candidate,
        ...parsedContactData,
        isLocked: !data.unlockedAt,
      });
      this.props.userData.fetchUserAndUsageData();
    } catch (e) {
      console.error("could not unlock candidate", e);
      if (e?.response?.data?.message === 'not enough credits for the user') {
        this.setState({ showInsufficientCreditsModal: true });
        return;
      }
      notificationController.showError(`Couldn't unlock the candidate${e.message ? `: ${e.message}` : ""}`);
    }
  };

  onCandidateUpdated = (candidate) => {
    candidate = discoverSearchService.parseCandidateData(candidate);
    this.updateResultItem(candidate);
  };

  onAddSelectedToProject = async (projectId) => {
    const { notificationController, userData } = this.props;

    await api.AddCandidatesToProject(
      projectId,
      Object.values(this.state.selected).map((item) => item.id)
    );
    this.setState({
      selected: {},
    });

    notificationController.showSuccess(`Selected candidates have been added!`);
    userData.fetchUserAndUsageData();
    this.performSearch({ showLoader : false });
  };

  onAddToProject = async (projectId, candidate) => {
    const { notificationController, userData } = this.props;
    await api.AddCandidatesToProject(projectId, [candidate.id]);
    notificationController.showSuccess(`${userData.state.user?.uiSettings?.mappings?.candidate} has been added!`);
    userData.fetchUserAndUsageData();
    this.performSearch({ showLoader : false });
  };

  // Only called when closing the modal
  onModalWindowToggle = () => {
    // Remove candidateID param when closing modal
    const { history } = this.props;
    if (history) {
      history.replace(`/discover${window.location.search}`);
    }

    this.setState({ candidateModalData: undefined });
  };

  render() {
    const {
      filters,
      result,
      selected,
      sidebarSize,
      loading,
      pagination,
      savedSearches,
      recentSavedSearches,
      candidateModalData,
      showInsufficientCreditsModal
    } = this.state;

    const selectedCount = Object.keys(selected).length;
    const isSelectedAll = result ? selectedCount === result.data?.length : undefined;

    return (
      <div className={css.discover}>
        <DiscoverFilters
          result={result}
          value={filters}
          savedSearches={savedSearches}
          recentSavedSearches={recentSavedSearches}
          onChange={this.onChangeFilter}
          onReset={this.onResetSearch}
          onSave={this.onSaveSearch}
          onDeleteSavedSearch={this.onDeleteSavedSearch}
          onRestoreSavedSearch={this.onRestoreSavedSearch}
          sidebarSize={sidebarSize}
          onChangeSidebarSize={this.onChangeSidebarSize}
        />
        <DiscoverSearchResults
          loading={loading}
          result={result}
          selected={selected}
          selectedCount={selectedCount}
          isSelectedAll={isSelectedAll}
          onSelect={this.onChangeSelection}
          onSelectAll={this.onSelectAll}
          onClearSelection={this.onClearSelection}
          onUnlockCandidate={this.onUnlockCandidate}
          currentPage={pagination.currentPage}
          onChangeCurrentPage={this.onChangeCurrentPage}
          pageSize={pagination.pageSize}
          onChangePageSize={this.onChangePageSize}
          onCandidateUpdated={this.onCandidateUpdated}
          onAddSelectedToProject={this.onAddSelectedToProject}
          onAddToProject={this.onAddToProject}
        />

        {!!candidateModalData && (
          <CandidateDetailModalWrapper
            viewModalOpenState
            onCandidateUpdated={this.onCandidateUpdated}
            uiSettings={this.props.userData.state.user?.uiSettings}
            candidate={candidateModalData.candidate}
            initialPage={candidateModalData.initialPage}
            toggleModalWindow={this.onModalWindowToggle}
            isNavigatorView={candidateModalData.isNavigatorView ?? true}
            result={this.state.result}
          />
        )}
        {showInsufficientCreditsModal && <InsufficientCreditsModal onClose={() => this.setState({ showInsufficientCreditsModal : false })} />}
      </div>
    );
  }
}

DiscoverNew = withNotifications(DiscoverNew);
DiscoverNew = (function withUserReducer(WrappedComponent) {
  return function (props) {
    const userData = useUserData();
    const routerParams = useParams();
    const history = useHistory();
    return <WrappedComponent {...props} userData={userData} routerParams={routerParams} history={history} />;
  };
})(DiscoverNew);

export { DiscoverNew };
