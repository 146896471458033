import * as React from "react";
import { PlanInfoCard } from "../../../../screens/profile/ProfileBilling/ui/PlanInfoCard/PlanInfoCard";
import { CandidateImportParseResult } from "../../../../screens/projects/CandidateService";
import { Button } from "../../../DiscoverNew/UI/Button/Button";
import { Link } from "../../../DiscoverNew/UI/Link/Link";
import SectionTitle from "../../../SectionTitle/SectionTitle";

import css from "./CandidateImport.module.css";

type Props = {
  data: CandidateImportParseResult
  projectId?: number
}

export const CandidateImportResult = (props: Props) => {
  return (
    <>
      <div className='max-width'>
        <div className={css.section}>
          <SectionTitle className={css.sectionTitle} level={2}>We have finished your import</SectionTitle>
          <PlanInfoCard style={{ display: 'flex', alignItems: 'flex-start', gap: 40 }}>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Imported New Candidates</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{props.data.to_create_count || 0}</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Updated Existing Candidates</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{props.data.to_update_count || 0}</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
            <PlanInfoCard.Item>
              <PlanInfoCard.ItemTitle>Enriched Candidates</PlanInfoCard.ItemTitle>
              <PlanInfoCard.ItemValue>{props.data.to_enrich_count || 0}</PlanInfoCard.ItemValue>
            </PlanInfoCard.Item>
          </PlanInfoCard>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {props.projectId ? (
            <div>
              <Button component="a" href={`/projects/${props.projectId}`}>View Imported Candidates</Button>
              <div style={{ textAlign: 'center', marginTop: 10 }}><Link style={{ fontSize: 'inherit' }} onClick={() => window.location.reload()}>start new import</Link></div>
            </div>
            )
            : <Button onClick={() => window.location.reload()}>Start New Import</Button>}
        </div>
      </div>
    </>
  )
}