import { cx } from "emotion";
import * as React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { InputClearButton } from "../Input/Input";
import inputCSS from "../Input/Input.module.css";

import css from "./Textarea.module.css";

type Props = {
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  minRows?: number;
  maxRows?: number;
  rightIcon?: React.ReactNode;
  clearable?: boolean;
  className?: string;
}

export function Textarea(props: Props) {
  const { onChange, value, defaultValue, placeholder } = props;
  const inputProps: any = {};

  if (onChange) {
    inputProps.onChange = onChange;
    inputProps.value = value || "";
  } else {
    inputProps.defaultValue = defaultValue;
  }

  return (
    <div
      className={cx(inputCSS.holder, props.className, {
        [inputCSS.hasRightIcon]: !!props.rightIcon,
        [inputCSS.clearable]: !!props.clearable && !!props.value,
      })}
    >
      {!!props.rightIcon && <div className={inputCSS.rightIcon}>{props.rightIcon}</div>}
      <TextareaAutosize {...inputProps} placeholder={placeholder} minRows={props.minRows} maxRows={props.maxRows || 5} className={css.textarea} />
      {/* @ts-ignore */}
      <InputClearButton onClick={() => props.onChange({ target: { value: "" } })} />
    </div>
  );
}