import { useSortable } from "@dnd-kit/sortable";
import { humanize } from "inflected";
import { CSS } from "@dnd-kit/utilities";
import { ButtonHTML } from "components/DiscoverNew/UI/ButtonHTML/ButtonHTML";
import { ContextMenuDropdown } from "components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";
import { Icon } from "components/DiscoverNew/UI/IconComponent/Icon";
import { Pill } from "components/ui/Pill/Pill";
import React from "react";
import { Button } from "../../../../components/DiscoverNew/UI/Button/Button";
import { TagSelectHtml } from "../../../../components/DiscoverNew/UI/SelectHtml/TagSelectHtml/TagSelectHtml";
import { InlineEditable } from "../../../../components/ui/InlineEditable/InlineEditable";
import { candidateService } from "../../../projects/CandidateService";
import { STAGE_COLORS, StageColor, StageFlowItemInterface, StageFlowItemUpdateDTOInterface } from "../StageFlowService";

import css from "./StagesTable.module.css";

type Props = {
  onDelete: () => void;
  onInlineEdit: (stageData: StageFlowItemUpdateDTOInterface) => void;
  stage: StageFlowItemInterface;
  stageStyle: React.CSSProperties;
  style?: React.CSSProperties;
  id: string | number;
  isEditable?: boolean;
  isDraggable?: boolean;
  isDeletable?: boolean;
  onShow?: () => void;
  onHide?: () => void;
} & ({
  mode: 'full'
  onShow: () => void;
  onHide: () => void;
} | {
  mode: 'short'
});

const colorOptions = STAGE_COLORS.map(color => {
  return {
    value : color,
    label : humanize(color),
  };
})

export const DraggableStageRow: React.FC<Props> = ({ mode, onDelete, onShow, onHide, isDeletable, stage, onInlineEdit, stageStyle, style, id, isEditable, isDraggable = true }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id : id });

  const renderDaysOnStage = () => {
    let label = '';
    const daysOnStage = stage.hoursToComplete ? stage.hoursToComplete / 24 : null;
    if (!daysOnStage) {
      label = " – ";
    } else {
      label = `${daysOnStage} ${daysOnStage === 1 ? "day" : "days"}`
    }

    if (isEditable) {
      return <InlineEditable
        inputStyle={{ width: 50}} value={daysOnStage}
        validator={v => !!v && !!v.trim() && !isNaN(parseInt(v)) && parseInt(v) > 0}
        onSave={v => {
          onInlineEdit({ ...stage, hoursToComplete: parseInt(v) * 24 })
        }}
        label={label}
      />
    }
    return label;
  }

  return (
    <tr
      ref={setNodeRef}
      className={css.tr + " " + (isDragging ? css.trDragging : "")}
      {...attributes}
      style={{
        backgroundColor : isDragging ? stageStyle.backgroundColor : "inherit",
        color : isDragging ? stageStyle.color : "inherit",
        transform : CSS.Transform.toString(transform),
        transition,
        ...style,
      }}
    >
      <td className={css.tdOrder}>
        {(isEditable && isDraggable) ? (
          <ButtonHTML className={css.dragHandle} type="button" {...listeners}>
            <Icon name="drag-handle" />
          </ButtonHTML>
        ) : (
          <span className={css.tdOrderNumber}>{stage.order}</span>
        )}
      </td>
      <td className={css.tdName}>
        {isEditable ? (
          <InlineEditable
            value={stage.name}
            validator={v => !!v && !!v.trim()}
            onSave={name => (name && name.trim()) ? onInlineEdit({ ...stage, name : name.trim() }) : null}
          />
        ) : stage.name}
      </td>
      <td className={css.tdDaysOnStage}>{renderDaysOnStage()}</td>
      <td className={css.tdColor}>
        <TagSelectHtml
          disabled={!isEditable}
          value={stage.color}
          style={{
            color : stageStyle.color,
            backgroundColor : stageStyle.backgroundColor,
            justifyContent : 'space-between',
          }}
          options={colorOptions}
          onChange={v => onInlineEdit({ ...stage, color : v.value })}
        />
      </td>
      {mode === 'full' && (
        <>
          <td>
            <TagSelectHtml
              disabled={!isEditable}
              value={stage.__hiddenAt ? 'hidden' : 'visible'}
              style={{
                ...candidateService.getStageStyle({
                  ...stage,
                  color : stage.__hiddenAt ? StageColor.GRAY : StageColor.BLUE
                }),
                minWidth : isEditable ? 80 : '',
                justifyContent : 'space-between',
              }}
              options={[{ value : 'visible', label : 'Visible' }, { value : 'hidden', label : 'Hidden' }]}
              onChange={v => v.value === 'visible' ? onShow() : onHide()}
            />
          </td>
        </>
      )}
      <td className={css.tdActions}>
        {(isEditable && isDeletable) ? (
          <ButtonHTML className={css.deleteButton} onClick={onDelete} type="button">
            <Icon name='fa-trash' />
          </ButtonHTML>
        ) : (
          <span className={css.tdActionsDisabled}>-</span>
        )}
      </td>
    </tr>
  );
};
