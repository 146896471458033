import * as React from "react";
import { useState } from "react";

import { Modal } from "react-bootstrap";
import { companyService } from "../../../screens/companies/CompanyService";
import { ProjectInterface } from "../../../screens/projects/CandidateService";
import { projectService } from "../../../screens/projects/ProjectService";
import { logger } from "../../../services/Logger";
import CloseButton from "../../button/CloseButton";
import { useNotifications } from "../../DiscoverNew/contexts/Notification/notifications";
import { CreateProjectFormInfo } from "../CreateProjectModal/CreateProjectFormInfo";
import { modalStyles } from "../modalStyles";

type Props = {
  projectId: string
  onClose: () => void
  onUpdated: (project: ProjectInterface) => void
}

type FormValues = {
  name: string
  description: string
  tags: { value: string, label: string }[]
  company?: { value: string, label: string, __isNew__?: boolean }
  companyWebsite?: string
}

export function EditProjectModal(props: Props) {

  const notifications = useNotifications();
  const [loading, setLoading] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [initialValues, setInitialValues] = useState<FormValues>({
    name : "",
    description : "",
    tags : [],
    company : undefined,
    companyWebsite : "",
  });

  const onUpdated = async (values: FormValues) => {
    setLoading(true);
    try {
      const project = await projectService.update(props.projectId, {
        name : values.name,
        description : values.description || "",
        tags : values.tags.map((item) => item.label.trim()).filter(Boolean),
      });
      if (initialValues.company?.value !== values.company?.value && values.company?.value) {
        await projectService.moveToCompany(props.projectId, parseInt(values.company.value));
      }
      notifications.showSuccess(`Project has been updated!`);
      // @ts-ignore
      props.onUpdated(project);
    } catch (err) {
      logger.error("Could not update project", err);
      notifications.showError(`Could not update project`);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    async function init() {
      setInitializing(true);
      try {
        const project = await projectService.fetchProject(props.projectId);
        setInitialValues({
          name : project.name,
          description : project.description,
          tags : project.tags ? project.tags.map((item: string) => ({ value : item, label : item })) : [],
          company : { value : project.company.id + "", label : project.company.name },
        });
      } catch (err) {
        logger.error("Could not fetch project", err);
      } finally {
        setInitializing(false);
      }
    }

    init();
  }, [props.projectId]);

  return (
    <Modal show={true} onHide={props.onClose} centered>
      <CloseButton onClick={props.onClose} />
      <div className={modalStyles.modalBody} style={{ minHeight : "initial" }}>
        <h3 className={modalStyles.modalTitle}>
          Edit project
        </h3>
        {initializing ? "Loading..." : <CreateProjectFormInfo
          loading={loading} buttonTitle={loading ? "Updating..." : "Update Project"}
          initialValues={initialValues} onSubmit={onUpdated}
        />}
      </div>
    </Modal>
  );
}