import { humanize } from "inflected";
import * as React from "react";
import { ContextMenuDropdown } from "../../../../../components/DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";
import { TagSelectHtml } from "../../../../../components/DiscoverNew/UI/SelectHtml/TagSelectHtml/TagSelectHtml";
import { Formatter } from "../../../../../components/DiscoverNew/util/Formatter";
import { shortId } from "../../../../../components/DiscoverNew/util/util";
import { Pill } from "../../../../../components/ui/Pill/Pill";
import { useAuthController } from "../../../../../contexts/AuthProvider";
import {
  OrganizationMemberInterface,
  OrganizationMemberRole,
  OrganizationMemberStatus,
  organizationService,
} from "../../../../../services/OrganizationService";
import { PermissionEnum, permissionService } from "../../../../projects/PermissionService";

import css from "./BillingMembersTable.module.css";

type Props = {
  data: Array<OrganizationMemberInterface>
  onDelete: (member: OrganizationMemberInterface) => void
  onDeactivate: (member: OrganizationMemberInterface) => void
  onActivate: (member: OrganizationMemberInterface) => void
  onEdit: (member: OrganizationMemberInterface) => void
  onResendInvitation: (member: OrganizationMemberInterface) => void
}

export const BillingMembersTable: React.FC<Props> = (props) => {
    const { data } = props;
    const user = useAuthController().user as any;

    const onChangeStatus = (status: OrganizationMemberStatus, member: OrganizationMemberInterface) => {
      if (status === member.status) {
        return;
      }

      if (status === OrganizationMemberStatus.Active) {
        props.onActivate(member);
      } else {
        props.onDeactivate(member);
      }
    }

    const renderMemberStatus = (member: OrganizationMemberInterface, canEdit: boolean) => {
      if (member.status === OrganizationMemberStatus.InvitationPending) {
        return <Pill style={organizationService.getMemberStyle(member)}>Invitation sent</Pill>;
      }

      if (!canEdit) {
        return <Pill style={organizationService.getMemberStyle(member)}>{humanize(member.status)}</Pill>;
      }

      return <TagSelectHtml
        value={member.status} style={organizationService.getMemberStyle(member)}
        options={[
          { value : OrganizationMemberStatus.Active, label : "Active" },
          { value : OrganizationMemberStatus.Deactivated, label : "View Only" },
        ]}
        onChange={option => onChangeStatus(option.value as OrganizationMemberStatus, member)}
      />;

    };

    return (
      <table className={css.table}>
        <thead>
          <tr>
            <th className={css.tdName}>Name</th>
            <th className={css.tdEmail}>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Credits</th>
            <th>Added</th>
            <th className={css.tdActions} />
          </tr>
        </thead>
        <tbody>
          {data.map((member, index) => {
            const canEditMember = member.role === OrganizationMemberRole.Owner ? user.id === member.id : permissionService.can(user, PermissionEnum.editOrganizationMembers, member);
            return (
              <tr key={member.id + shortId()} className={css.tr}>
                <td className={css.tdName}>{Formatter.fullName(member) || "–"}</td>
                <td className={css.tdEmail}>{member.email}</td>
                <td>{humanize(member.role)}</td>
                <td>
                  {renderMemberStatus(member, canEditMember)}
                </td>
                <td>{member.credits.used?.toLocaleString() || 0} of {member.credits.limit?.toLocaleString() || 0}</td>
                <td>{member.invitedAt ? Formatter.fullDate(member.invitedAt) : "–"}</td>
                <td className={css.tdActions}>
                  {canEditMember && (
                    <ContextMenuDropdown>
                      {member.status === OrganizationMemberStatus.Active && (
                        <ContextMenuDropdown.Item icon="pencil-2" onClick={() => props.onEdit(member)}>
                          Edit
                        </ContextMenuDropdown.Item>
                      )}
                      {[OrganizationMemberStatus.InvitationPending, OrganizationMemberStatus.InvitationDeclined].includes(member.status) && (
                        <ContextMenuDropdown.Item icon="fa-arrow-rotate-right"
                                                  onClick={() => props.onResendInvitation(member)}>
                          Resend Invitation
                        </ContextMenuDropdown.Item>
                      )}
                      {member.status === OrganizationMemberStatus.Deactivated && (
                        <ContextMenuDropdown.Item icon="fa-play" onClick={() => props.onActivate(member)}>
                          Allow Credits Usage
                        </ContextMenuDropdown.Item>
                      )}
                      {member.status === OrganizationMemberStatus.Active && (
                        <ContextMenuDropdown.Item icon="fa-pause" onClick={() => props.onDeactivate(member)}>
                          Make View Only
                        </ContextMenuDropdown.Item>
                      )}
                      {/* todo */}
                      {/*{member.role !== OrganizationMemberRole.Owner && member.id !== user.id && (*/}
                      {/*  <ContextMenuDropdown.DangerItem icon="fa-trash" onClick={() => props.onDelete(member)}>*/}
                      {/*    Delete*/}
                      {/*  </ContextMenuDropdown.DangerItem>*/}
                      {/*)}*/}
                    </ContextMenuDropdown>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
;