import * as React from "react";
import { useQueryParams } from "./useQueryParams";
import { DEFAULT_PAGE_SIZE } from "../constants/constants";

// @todo scale up to "page state" or delete

export type UsePaginationHook = {
  limit: number;
  page: number;
  skip: number;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
}

export const usePagination = (defaultLimit?: number): UsePaginationHook => {
  const {
    params: { page = 1, limit = (defaultLimit || DEFAULT_PAGE_SIZE) },
    setParams,
  } = useQueryParams();
  return {
    limit,
    page: parseInt(page),
    skip: (page - 1) * limit,
    setPage: (page: number) => setParams({ page }),
    setLimit: (limit: number) => setParams({ limit, page: 1 }),
  };
};
