import { COLLABORATOR_ROLE } from "../../constants/constants";
import { authProviderInstance } from "../../contexts/AuthProvider";

export const PermissionEnum = {
  projectInviteCollaborator: "projectInviteCollaborator",
  projectEditCollaboratorRole: "projectEditCollaboratorRole",
  projectCreate: "projectCreate",
  projectEdit: "projectEdit",
  projectDelete: "projectDelete",
  companyCreate: "companyCreate",
  companyEdit: "companyEdit",
  companyDelete: "companyDelete",
  discover: "discover",
  projectAddCandidate: "projectAddCandidate",
  projectUpdateCandidateStage: "projectUpdateCandidateStage",
  projectImportCandidate: "projectImportCandidate",
  projectRemoveCandidate: "projectRemoveCandidate",
  projectArchiveCandidate: "projectArchiveCandidate",
  projectExportCandidate: "projectExportCandidate",
  projectCanFetchOrganization: "projectCanFetchOrganization",
  stageFlowCreate: "stageFlowCreate",
  stageFlowEdit: "stageFlowEdit",
  editOrganizationBilling: "editOrganizationBilling",
  editOrganizationMembers: "editOrganizationMembers",
  editOrganizationMembersRole: "editOrganizationMembersRole",
  editOrganizationInfo: "editOrganizationInfo",
  editOrganizationOwner: "editOrganizationMember",
  editOrganizationIntegrations: "editOrganizationIntegrations",
};

const { ADMIN, EDITOR, VIEWER, OWNER } = COLLABORATOR_ROLE;
export const ROLE_LEVEL = {
  [OWNER]: 0,
  [ADMIN]: 1,
  [EDITOR]: 2,
  [VIEWER]: 3,
};

const ORG_ROLE_LEVEL = {
  "owner": 0,
  "super-admin": 1,
  "admin": 2,
  "member": 3,
};

const resolvePermission = {
  /**
   * @param {CurrentUserInterface} user
   * @returns {boolean}
   */
  [PermissionEnum.editOrganizationOwner]: (user) => {
    return user.seat.permissions.some(p => ['organization::owner'].includes(p));
  },

  /**
   * @param {CurrentUserInterface} user
   * @returns {boolean}
   */
  [PermissionEnum.editOrganizationMembers]: (user, subject) => {
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    let res = permissions.some(p => user.seat.permissions.includes(p))
    if (subject && res) {
      res = ORG_ROLE_LEVEL[subject.role] >= ORG_ROLE_LEVEL[user.seat.role];
    }
    return res;
  },

  /**
   * @param {CurrentUserInterface} user
   * @returns {boolean}
   */
  [PermissionEnum.editOrganizationBilling]: (user) => {
    const permissions = ['organization::owner', 'organization::super-admin']
    return permissions.some(p => user.seat.permissions.includes(p))
  },

  /**
   * @param {CurrentUserInterface} user
   * @returns {boolean}
   */
  [PermissionEnum.editOrganizationInfo]: (user) => {
    const permissions = ['organization::owner', 'organization::super-admin']
    return permissions.some(p => user.seat.permissions.includes(p))
  },

  /**
   * @param {CurrentUserInterface} user
   * @returns {boolean}
   */
  [PermissionEnum.editOrganizationMembersRole]: (user) => {
    const permissions = ['organization::owner', 'organization::super-admin']
    return permissions.some(p => user.seat.permissions.includes(p))
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectInviteCollaborator]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[ADMIN];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.discover]: () => {
    if (!authProviderInstance.state.user.seat.isActive) {
      return false;
    }
    return true;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectExportCandidate]: (project) => {
    if (!authProviderInstance.state.user.seat.isActive) {
      return false;
    }
    return true;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectAddCandidate]: () => {
    if (!authProviderInstance.state.user.seat.isActive) {
      return false;
    }
    return true;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectImportCandidate]: () => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (user.email !== 'ddaly@bundorangroup.com' && !user.isAdmin) {
      return false;
    }
    return true;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectUpdateCandidateStage]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[EDITOR];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectRemoveCandidate]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[EDITOR];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectArchiveCandidate]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    return level <= ROLE_LEVEL[EDITOR];
  },

  /**
   * @returns {boolean}
   */
  [PermissionEnum.stageFlowCreate]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    const isActiveMember = user.seat.permissions.includes('organization::member') && user.seat.isActive;
    return isAllowedByRole || isActiveMember;
  },

  /**
   * @param {StageFlowInterface} stageFlow
   * @returns {boolean}
   */
  [PermissionEnum.stageFlowEdit]: (stageFlow) => {
    const { user } = authProviderInstance.state;
    if (stageFlow.isDefault) {
      return false;
    }
    if (stageFlow.organizationId !== user.organizationV2.id) {
      return false;
    }
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    const isActiveMember = user.seat.permissions.includes('organization::member') && user.seat.isActive;
    return isAllowedByRole || (isActiveMember && stageFlow.createdBy.id === user.id)
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectEdit]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    return level < ROLE_LEVEL[VIEWER];
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectDelete]: (project) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId !== user.organizationV2.id) {
      return false;
    }

    const permissions = ['organization::owner'];
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p));
    return isAllowedByRole;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.projectCreate]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    const isActiveMember = user.seat.permissions.includes('organization::member') && user.seat.isActive;
    return isAllowedByRole || isActiveMember;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.companyCreate]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    const isActiveMember = user.seat.permissions.includes('organization::member') && user.seat.isActive;
    return isAllowedByRole || isActiveMember;
  },

  /**
   * @param {ProjectInterface} project
   * @returns {boolean}
   */
  [PermissionEnum.companyEdit]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    const isActiveMember = user.seat.permissions.includes('organization::member') && user.seat.isActive;
    return isAllowedByRole || isActiveMember;
  },

  /**
   * @returns {boolean}
   */
  [PermissionEnum.companyDelete]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    return isAllowedByRole;
  },

  /**
   * @param {ProjectInterface} project
   * @todo
   * @param {any} collaborator
   * @returns {boolean}
   */
  [PermissionEnum.projectEditCollaboratorRole]: (project, collaborator) => {
    const { user } = authProviderInstance.state;
    if (!user.seat.isActive) {
      return false;
    }
    if (project.organizationId === user.organizationV2.id) {
      const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
      const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
      if (isAllowedByRole) {
        return true;
      }
    }
    const level = ROLE_LEVEL[project.currentUserRole];
    const subjectLevel = ROLE_LEVEL[collaborator.role];
    return level < subjectLevel;
  },

  /**
   * @returns {boolean}
   */
  [PermissionEnum.projectCanFetchOrganization]: () => {
    const user = authProviderInstance.state.user;
    const permissions = ['organization::owner', 'organization::super-admin', 'organization::admin']
    const isAllowedByRole = permissions.some(p => user.seat.permissions.includes(p))
    return isAllowedByRole;
  },
};

class PermissionService {
  can(who, what, extra) {
    const resolve = resolvePermission[what];
    if (resolve) {
      try {
        return resolve(who, extra);
      } catch (err) {
        console.error(`[permissionService] >> [${what}]`, err);
        return false;
      }
    }

    return false;
  }
}

export const permissionService = new PermissionService();
