import cn from "classnames";
import React from "react";
import { BillingPeriod, PlanInfoInterface } from "../../../../../../services/BillingService";

import styles from "./PlanCard.module.css";

type Props = {
  data: PlanInfoInterface
  align?: 'center' | 'left',
  onChoose: () => void
  buttonTitle?: string
  loading?: boolean
}

const PlanCard = ({ data, align, onChoose, buttonTitle, loading }: Props) => {
  const { name, title, pricePerSeat, creditsPerSeat, creditsPerSeatSingleUse, billingPeriod } = data;
  const billingPeriodLabel = billingPeriod === BillingPeriod.monthly ? "month" : "year";
  const modifiedTitle = name === "sourcing" ? 'Sourcing' : title;
  return (
      <div className={cn(styles.planCard, {
        [styles.orange]: name === "trial",
        [styles.cyan]: name === "sourcing",
        [styles.left]: align === "left",
      })}>
        <span className={styles.price}>$ {pricePerSeat}{!!pricePerSeat && <small>/ {billingPeriodLabel}</small>}</span>
        <span className={styles.explanation}>
          {name === 'trial' ? 'try payment free for 14 days' : (
            !!pricePerSeat && (billingPeriod === BillingPeriod.monthly ? "per seat, billed month-to-month" : "per seat, billed annually")
          )}
        </span>
        <span className={styles.divider} />
        <span className={styles.name}>{modifiedTitle}</span>
        {!!creditsPerSeat && (
          <span className={styles.limit}>{creditsPerSeat.toLocaleString()} credits<small>/ {billingPeriodLabel}</small></span>
        )}
        {!!creditsPerSeatSingleUse && (
          <span className={styles.limit}>{creditsPerSeatSingleUse.toLocaleString()} credits</span>
        )}
        <button className={styles.button} onClick={onChoose} disabled={loading}>
          {loading ? 'Processing...' : (buttonTitle || 'Choose')}
        </button>
      </div>
  );
};

export default PlanCard;
