import { cx } from "emotion";
import * as React from "react";
import { useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { StageFlowInterface } from "screens/profile/ProfileStageFlow/StageFlowService";
import { getShortenedString } from "utils/strings";
import { COLLABORATOR_ROLE, ROUTES } from "../../../../constants/constants";
import { ProjectCandidateInterface, ProjectInterface } from "../../../../screens/projects/CandidateService";
import { PermissionEnum, permissionService } from "../../../../screens/projects/PermissionService";
import { Input } from "../../../DiscoverNew/Form/Input/Input";
import { Button } from "../../../DiscoverNew/UI/Button/Button";
import { ContextMenuDropdown } from "../../../DiscoverNew/UI/ContextMenuDropdown/ContextMenuDropdown";
import { Icon } from "../../../DiscoverNew/UI/IconComponent/Icon";
import { ViewMode, ViewModeSwitcher } from "../../../DiscoverNew/UI/ViewModeSwitcher/ViewModeSwitcher";
import { EditProjectModal } from "../../../modals/EditProjectModal/EditProjectModal";
import InviteCollaboratorModal from "../../../modals/InviteCollaboratorModal/InviteCollaboratorModal";
import { UserImage } from "../../../ui/ProfileImage/UserImage";

import css from "./CandidatesPageHeader.module.css";

type Props = {
  searchValue: string;
  onSearchChange: (value: string) => void;
  onNewCandidateClick: () => void;
  viewMode: ViewMode;
  onViewModeChange: (value: ViewMode) => void;
  className?: string;
  selectedCandidates: Array<ProjectCandidateInterface>;
  project?: ProjectInterface;
  projectId?: string | number | null;
  stageFlow?: StageFlowInterface;
  onDeleteSelectedManuallyAddedCandidates: () => void;
  onRemoveSelected: () => void;
  onArchiveSelected: () => void;
  onUnarchiveSelected: () => void;
  onChangeStageSelected: (stageId: number) => void;
  onProjectEdited: () => void;
};

export const CandidatesPageHeader: React.FC<Props> = (props) => {
  const {
    onSearchChange,
    searchValue,
    onNewCandidateClick,
    viewMode,
    onViewModeChange,
    className,
    project,
    stageFlow,
  } = props;

  const history = useHistory();

  const [isInviteCollaboratorModalOpen, setIsInviteCollaboratorModalOpen] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);

  const canEdit = props.projectId !== 'manually' ? permissionService.can(project, PermissionEnum.projectEdit) : '';
  const canArchive = props.projectId !== 'manually' ? permissionService.can(props.project, PermissionEnum.projectArchiveCandidate) : true;
  const canUpdateStage = props.projectId !== 'manually' ? permissionService.can(props.project, PermissionEnum.projectUpdateCandidateStage) : true;
  const canRemoveFromProject = props.projectId !== 'manually' ? permissionService.can(props.project, PermissionEnum.projectRemoveCandidate) : true;
  const canImportCandidate = props.projectId !== 'manually' ? permissionService.can(props.project, PermissionEnum.projectImportCandidate) : true;
  const canDiscover = permissionService.can(null, PermissionEnum.discover);
  const canInviteCollaborator = props.projectId !== 'manually' ? permissionService.can(props.project, PermissionEnum.projectInviteCollaborator) : true;

  const containsArchivedOnly = props.selectedCandidates.every(candidate => !!candidate.archivedAt);
  const containsUnarchivedOnly = props.selectedCandidates.every(candidate => !candidate.archivedAt);

  return (
    <header className={cx(css.holder, className)} style={{ paddingLeft : project ? 13 : 22 }}>
      <div className={css.flexRow}>
        <span>
          <h3 className={css.title}>
            {project ? (
              <>
                <NavLink className={css.link} to={`${ROUTES.projects}/${project.id}`} key={project.id}>
                  {getShortenedString(project.name, 37)}
                  {!!project?.ownedBy && project.currentUserRole !== COLLABORATOR_ROLE.OWNER && (
                    <Icon name="users" style={{ position : "relative", marginLeft : 8, top : 2 }}
                          title="This is a shared project" />
                  )}
                </NavLink>
                <div>
                  <span className={css.companyName}>{project.company.name}</span>
                </div>
              </>
            ) : props.projectId === "manually" ? (
              "Manually Added Candidates"
            ) : (
              "All Candidates"
            )}
          </h3>
        </span>
        {project?.ownedBy && project.currentUserRole !== COLLABORATOR_ROLE.OWNER && (
          <UserImage data={project.ownedBy} style={{ marginLeft : 10, display : "block" }} />
        )}
        <Input
          className={css.search}
          placeholder="Search by candidate name"
          value={searchValue}
          onChange={(e: any) => onSearchChange(e.target.value)}
          leftIcon={<Icon name="search" />}
        />
        {!!project && <ViewModeSwitcher value={viewMode} onChange={onViewModeChange} className={css.viewSwitcher} />}
      </div>
      <div className={css.flexRow}>
        {!!project && (
          <>
            <ContextMenuDropdown
              contentStyle={{ width: 230, maxHeight: 250 }}
              label={<ContextMenuDropdown.ActionButton />}
            >
              {!!props.selectedCandidates?.length && !!project && (
                <div style={{ marginBottom: 8 }}>
                  {canUpdateStage && !!stageFlow && (
                    <ContextMenuDropdown.Item icon="move" style={{ position: 'relative' }} onClick={() => {}}>
                      Move to Stage
                      <select className={css.select} onChange={e => props.onChangeStageSelected(parseInt(e.target.value))} value="">
                        {stageFlow?.stages?.map((item, index) => (
                          <option key={index} value={item.stageId}>{item.name}</option>
                        ))}
                      </select>
                    </ContextMenuDropdown.Item>
                  )}
                  {canArchive && (
                    <>
                      {containsArchivedOnly ? (
                        <ContextMenuDropdown.Item icon="archive" onClick={props.onUnarchiveSelected}>
                          Unarchive
                        </ContextMenuDropdown.Item>
                      ) : containsUnarchivedOnly ? (
                        <ContextMenuDropdown.Item icon="archive" onClick={props.onArchiveSelected}>
                          Archive
                        </ContextMenuDropdown.Item>
                      ) : null}
                    </>
                  )}
                  {canRemoveFromProject && (
                    <ContextMenuDropdown.DangerItem icon='fa-trash' onClick={props.onRemoveSelected}>
                      Remove
                    </ContextMenuDropdown.DangerItem>
                  )}
                </div>
              )}
              {canEdit && (
                <ContextMenuDropdown.Item icon='pencil' onClick={() => setIsEditModalOpened(true)}>
                  Edit Project
                </ContextMenuDropdown.Item>
              )}
              {!!stageFlow && permissionService.can(stageFlow, PermissionEnum.stageFlowEdit) && (
                // we kept inline styles here because this page is more likely to become a legacy one, so we don't need to refactor this as of right now
                <ContextMenuDropdown.Item title={`Interview Process: ${stageFlow.name}`} icon='gear' component={Link} to={`/profile/interview-process/${stageFlow!.stageFlowId}`}>
                  Configure Interview Process
                </ContextMenuDropdown.Item>
              )}
              {canDiscover && (
                <ContextMenuDropdown.Item icon='plus' component={Link} to={`/discover${project.tags ? `?skills=${project.tags}` : ""}`}>
                  Discover More
                </ContextMenuDropdown.Item>
              )}
              {canImportCandidate && (
                <ContextMenuDropdown.Item icon='add-contact' onClick={() => history.push(ROUTES.candidatesImport + (project ? `?projectId=${project.id}` : ''))}>
                  Import Candidates
                </ContextMenuDropdown.Item>
              )}
              {canInviteCollaborator && (
                <ContextMenuDropdown.Item icon='plus' onClick={() => setIsInviteCollaboratorModalOpen(true)}>
                  Invite Collaborator
                </ContextMenuDropdown.Item>
              )}
            </ContextMenuDropdown>
          </>
        )}
        {props.projectId === "manually" && !!props.selectedCandidates?.length && (
          <ContextMenuDropdown
            contentStyle={{ width : 230, maxHeight : 250 }}
            label={<ContextMenuDropdown.ActionButton />}
          >
            <ContextMenuDropdown.DangerItem icon="fa-trash" onClick={props.onDeleteSelectedManuallyAddedCandidates}>
              Delete
            </ContextMenuDropdown.DangerItem>
          </ContextMenuDropdown>
        )}
        {props.projectId === "manually" && (
          <Button className={css.newCandidateButton} leftIcon={<Icon name="plus" />} onClick={onNewCandidateClick}>New Candidate</Button>
        )}
      </div>

      {isEditModalOpened && (
        <EditProjectModal
          projectId={props.projectId + ''}
          onUpdated={() => { props.onProjectEdited(); setIsEditModalOpened(false) }}
          onClose={() => setIsEditModalOpened(false)}
        />
      )}
      {isInviteCollaboratorModalOpen && (
        <InviteCollaboratorModal
          project={project}
          onClose={() => setIsInviteCollaboratorModalOpen(false)}
          isOpen={isInviteCollaboratorModalOpen}
        />
      )}
    </header>
  );
};
