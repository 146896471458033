import React, { useEffect } from "react";
import { injectStyleOverride } from "../../../components/DiscoverNew/DiscoverNewWrapper";
import SectionTitle from "../../../components/SectionTitle/SectionTitle";
import BillingMembers from "../ProfileBilling/BillingMembers/BillingMembers";

import styles from "../ProfileBilling/ProfileBilling.module.css";

const ProfileTeamManagement = () => {
  useEffect(() => {
    return injectStyleOverride();
  }, []);

  return (
    <div className={styles.card}>
      <div className={styles.head}>
        <SectionTitle className={styles.title} level={2}>
          Team Management
        </SectionTitle>
      </div>
      <div className={styles.tabContent}>
        <BillingMembers />
      </div>
    </div>
  );
};

export default ProfileTeamManagement;
