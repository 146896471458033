import cn from "classnames";
import * as React from "react";
// @ts-ignore
import { Spinner } from "reactstrap";

import validationTrustedIcon from "../../../../../assets/images/contact/email-badge-trusted.svg";
import validationUntrustedIcon from "../../../../../assets/images/contact/email-badge-untrusted.svg";
import { useUserData } from "../../../../../contexts/userContext";
import { CandidateImportParseResult } from "../../../../../screens/projects/CandidateService";
import { logger } from "../../../../../services/Logger";
import { Checkbox } from "../../../../DiscoverNew/Form/Checkbox/Chekbox";
import { Select, SelectOption } from "../../../../DiscoverNew/Form/Select/Select";
import { Icon } from "../../../../DiscoverNew/UI/IconComponent/Icon";
import { shortId } from "../../../../DiscoverNew/util/util";
import SectionTitle from "../../../../SectionTitle/SectionTitle";
import { Alert } from "../../../../ui/Alert/Alert";
import { CandidateImportFooter } from "../CandidateImportFooter";

import css from "./CandidateImportMapping.module.css";
import importCSS from '../CandidateImport.module.css'

export type ColumnsMapping = Record<string, string>

export type CandidateImportMappingStepValue = {
  columnsMapping: ColumnsMapping
}

type Props = {
  onSubmit: (enrich: boolean, v: ColumnsMapping) => Promise<void>
  data: CandidateImportParseResult
}

export const CandidateImportMapping = (props: Props) => {
  const {
    state: { userCredits },
  } = useUserData();
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [columnsMapping, setColumnsMapping] = React.useState(getColumnsMapping());
  const [skipUnmapped, setSkipUnmapped] = React.useState(false);
  const [enrich, setEnrich] = React.useState(false);

  const onSubmit = async () => {
    if (!Object.values(columnsMapping).includes('social_profile_url') && !Object.values(columnsMapping).includes('email')) {
      setError('To import candidates you need to have at least "Email" or "Social Profile" Chatterworks properties mapped');
      return;
    }

    setError('');

    try {
      setLoading(true);
      await props.onSubmit(enrich, columnsMapping);
      setLoading(false);
    } catch(err) {
      logger.error('CandidateImportMapping:onSubmit', err);
      setError('It seems that one of the columns is not mapped correctly. Please check the mapping and try again');
      setLoading(false);
    }
  };

  function getColumnsMapping() {
    return Object.entries(props.data.header_map).reduce((acc, item) => {
      if (!item[1].SystemName) {
        return acc;
      }
      return {
        ...acc,
        [item[0]] : item[1].SystemName,
      };
    }, {} as Record<string, string>);
  }

  const chatterworksPropertyOptions = Object.entries(props.data.available_columns).map(item => {
    const systemName = item[1];
    return {
      value : systemName,
      label : item[0],
    };
  });

  const columnsCount = Object.keys(props.data.header_map).length;
  const mappedColumnsCount = Object.values(columnsMapping).filter(Boolean).length;

  const canNext = mappedColumnsCount === columnsCount || skipUnmapped;

  const renderEnrichSection = () => {
    const header = (
      <SectionTitle level={2} style={{ marginTop : 40 }}>
        Enrich your data with Chatterworks (optional)
      </SectionTitle>
    )

    if (!!props.data.to_enrich_count) {
      if (userCredits >= props.data.to_enrich_count) {
        return (
          <div>
            {header}
            <p style={{ marginTop : 10 }}>
              We can enrich your data with additional information like social profiles, contact info, job titles, and
              more.<br />
              Your file
              contains <b>{props.data.to_enrich_count}</b> candidate{props.data.to_enrich_count > 1 && "s"} that can be
              enriched.
            </p>
            <div className={cn(css.enrichmentCard, enrich && css.active)} style={{ marginTop : 10 }}
                 onClick={() => setEnrich(!enrich)}>
              <div className={css.checkIconHolder}>
                <Icon name="tick2" className={css.checkIcon} iconStyle={{ width : 12, height : "auto" }} />
              </div>
              <div>
                <SectionTitle level={3} className={css.enrichmentCardTitle}>I want to enrich my candidates</SectionTitle>
                <p className={css.enrichmentCardHelpText}>
                  It will consume {props.data.to_enrich_count} credits (1 credit per candidate); you have {(userCredits || 0).toLocaleString()} available.
                </p>
              </div>
            </div>
          </div>
        )
      }
      return (
        <div>
          {header}
          <p style={{ marginTop : 10 }}>
            We can enrich your data with additional information like social profiles, contact info, job titles, and
            more.<br />
            Your file
            contains <b>{props.data.to_enrich_count}</b> candidate{props.data.to_enrich_count > 1 && "s"} that can be
            enriched.
          </p>
          <div style={{ display : "flex", gap : 24 }}>
            <div className={cn(css.enrichmentCard, enrich && css.active)}
                 style={{ opacity : 0.5, pointerEvents : "none", flexShrink: 0, alignItems: 'center' }}
                 onClick={() => setEnrich(!enrich)}>
              <div className={css.checkIconHolder}>
                <Icon name="tick2" className={css.checkIcon} iconStyle={{ width : 12, height : "auto" }} />
              </div>
              <SectionTitle level={3} className={css.enrichmentCardTitle} style={{ marginBottom : 0 }}>I want to enrich my
                candidates</SectionTitle>
            </div>
            <Alert style={{ borderRadius : 8, background : "none", flex: 1 }}>
              You need more credits to complete this action.<br/>
              Enriching this list requires {props.data.to_enrich_count} credit{props.data.to_enrich_count > 1 && 's'} (1 credit per candidate); you have {(userCredits || 0).toLocaleString()} credits available.<br/>
              Please purchase more credits or import candidates without enrichment.
            </Alert>
          </div>
        </div>
      )
    }
    return (
      <div>
        {header}
        <p style={{ marginTop : 10 }}>
          We can enrich your data with additional information like social profiles, contact info, job titles, and
          more.<br />
        </p>
        <div style={{ display : "flex", gap : 24 }}>
          <div className={cn(css.enrichmentCard, enrich && css.active)}
               style={{ opacity : 0.5, pointerEvents : "none", flexShrink : 0, alignItems: 'center' }}
               onClick={() => setEnrich(!enrich)}>
            <div className={css.checkIconHolder}>
              <Icon name="tick2" className={css.checkIcon} iconStyle={{ width : 12, height : "auto" }} />
            </div>
            <SectionTitle level={3} className={css.enrichmentCardTitle} style={{ marginBottom : 0 }}>I want to enrich my
              candidates</SectionTitle>
          </div>
          <Alert
            style={{ color : "var(--c-blue)", borderColor : "var(--c-blue)", background : "none", borderRadius : 8, flex: 1 }}>We
            didn't find any candidates in your file that we could enrich.<br/> You can still import candidates without
            enrichment.</Alert>
        </div>
      </div>
    );
  }

  return (
    <div className={css.container}>
      <div className="max-width">
        {loading ? (
          <div className={importCSS.loader}>
            <Spinner color="primary" />
            <SectionTitle level={3}>We are importing your candidates...<br />Most imports finish within 20
              seconds</SectionTitle>
          </div>
        ) : (
          <>
            <SectionTitle level={2} style={{ marginTop : 40 }}>
              Map columns in your file to Chatterworks properties
            </SectionTitle>
            {!!error && <Alert style={{ marginTop : 40, fontSize : 14 }}>{error}</Alert>}
            <table className={css.table}>
              <thead>
                <tr>
                  <th className={css.tdName}>Column header from file</th>
                  <th>Preview information</th>
                  <th className={css.tdMappingStatus}>Mapping status</th>
                  <th>Chatterworks property</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(props.data.header_map).map(item => {
                  const [headerFromFile, value] = item;
                  return (
                    <tr key={headerFromFile}>
                    <td className={css.tdName}>
                        {headerFromFile}
                      </td>
                      <td>
                        {value.PreviewValues ? <ul className={css.previewList}>
                          {value.PreviewValues.map(pv => {
                            const v = pv || '–';
                            return <li key={shortId()} title={v}>{v}</li>;
                          })}
                        </ul> : '–'}
                      </td>
                      <td className={css.tdMappingStatus}>
                        {columnsMapping[headerFromFile] ? <img width={24} src={validationTrustedIcon} /> :
                          <img width={24} src={validationUntrustedIcon} title="This fielf is not selected" />}
                      </td>
                      <td>
                        <Select
                          isClearable
                          isOptionDisabled={(option) => {
                            return Object.values(columnsMapping).includes(option.value as string);
                          }}
                          value={chatterworksPropertyOptions.find(item => item.value === columnsMapping[headerFromFile])}
                          options={chatterworksPropertyOptions}
                          onChange={(e) => {
                            setColumnsMapping({
                              ...columnsMapping,
                              [headerFromFile] : e === null ? '' : (e as SelectOption).value as string
                            });
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {renderEnrichSection()}
          </>
        )}
      </div>
      <CandidateImportFooter nextButtonTitle="Start Import" onNext={onSubmit} nextButtonDisabled={!canNext || loading}>
        <div style={{ textAlign : 'right' }}>
          <b>Mapped {mappedColumnsCount}/{columnsCount} columns</b>
          {columnsCount !== mappedColumnsCount && (
            <div className={css.skipUnmapped} onClick={() => setSkipUnmapped(!skipUnmapped)}>
              <Checkbox checked={skipUnmapped} />
              Don’t import data in unmapped columns
            </div>
          )}
        </div>
      </CandidateImportFooter>
    </div>
  );
};