import * as React from 'react';
import {Collapse} from 'react-collapse';
import css from './FieldsetCollapsible.module.css'

const IconChevron = () => (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 9C7.71875 9 7.46875 8.90625 7.28125 8.71875L1.28125 2.71875C0.875 2.34375 0.875 1.6875 1.28125 1.3125C1.65625 0.90625 2.3125 0.90625 2.6875 1.3125L8 6.59375L13.2812 1.3125C13.6562 0.90625 14.3125 0.90625 14.6875 1.3125C15.0938 1.6875 15.0938 2.34375 14.6875 2.71875L8.6875 8.71875C8.5 8.90625 8.25 9 8 9Z" fill="#408CF6"/>
  </svg>
)

type Props = {
  children: React.ReactNode
  title: string
  style?: React.CSSProperties
  isOptional?: boolean
}

export function FieldsetCollapsible(props: Props) {
  const [isOpened, setIsOpened] = React.useState(false);
  return (
    <fieldset className={isOpened ? css.isOpened : ''} style={props.style}>
      <header className={css.header} onClick={() => setIsOpened(!isOpened)}>
        <legend className={css.title}>{props.title}</legend>
        {props.isOptional && <span className={css.optional}>(optional)</span>}
        <button className={css.openButton} type='button'><IconChevron /></button>
      </header>
      <Collapse isOpened={isOpened}>
        <div className={css.content}>
          {props.children}
        </div>
      </Collapse>
    </fieldset>
  )
}