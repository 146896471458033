import { joiResolver } from "@hookform/resolvers/joi";
import cn from "classnames";
import Joi from "joi";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import {
  CandidateEmailType,
  CandidateEmailTypeOptions,
  CandidatePhoneType,
  CandidatePhoneTypeOptions,
  candidateService,
  CreateCandidateDTO,
} from "../../../../../screens/projects/CandidateService";
import { useNotifications } from "../../../../DiscoverNew/contexts/Notification/notifications";
import { FormField } from "../../../../DiscoverNew/Form/FormField/FormField";
import { Input } from "../../../../DiscoverNew/Form/Input/Input";
import { InputPhone } from "../../../../DiscoverNew/Form/InputPhone/InputPhone";
import { Select, SelectOption } from "../../../../DiscoverNew/Form/Select/Select";
import { Textarea } from "../../../../DiscoverNew/Form/Textarea/Textarea";
import { Button } from "../../../../DiscoverNew/UI/Button/Button";
import { FieldsetCollapsible } from "../../../../DiscoverNew/UI/FieldsetCollapsible/FieldsetCollapsible";
import { Loading } from "../../../../DiscoverNew/UI/Loading/Loading";
import { InputDate } from "../../../../input/InputDate/InputDate";

import css from "./CandidateForm.module.css";

const PlusIcon = () => (
  <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.125 5C10.125 5.32812 9.86719 5.5625 9.5625 5.5625H5.8125V9.3125C5.8125 9.64062 5.55469 9.89844 5.25 9.89844C4.92188 9.89844 4.6875 9.64062 4.6875 9.3125V5.5625H0.9375C0.609375 5.5625 0.375 5.32812 0.375 5.02344C0.375 4.69531 0.609375 4.4375 0.9375 4.4375H4.6875V0.6875C4.6875 0.382812 4.92188 0.148438 5.25 0.148438C5.55469 0.148438 5.8125 0.382812 5.8125 0.6875V4.4375H9.5625C9.86719 4.4375 10.125 4.69531 10.125 5Z"
      fill="#408CF6"
    />
  </svg>
);

const TrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    <path
      d="M424 80C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H412.4L388.4 452.7C385.9 486.1 358.1 512 324.6 512H123.4C89.92 512 62.09 486.1 59.61 452.7L35.56 128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94L354.2 80H424zM177.1 48C174.5 48 171.1 49.34 170.5 51.56L151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1zM364.3 128H83.69L107.5 449.2C108.1 457.5 115.1 464 123.4 464H324.6C332.9 464 339.9 457.5 340.5 449.2L364.3 128z"
      fill='#f83e3e'
    />
  </svg>
);

type Props = {
  onSuccess: any
  candidateId?: number
}

const schema = Joi.object({
  firstName: Joi.string().required(),
  lastName: Joi.string().required(),
  profileUrl: Joi.string().optional(),
  experiences: Joi.array().items(Joi.object().custom((value, helpers) => {
    if (!!value.companyName && (value.startDate || value.endDate)) {
      const startDate = DateTime.fromJSDate(value.startDate);
      const endDate = DateTime.fromJSDate(value.endDate);
      if (!startDate.isValid) {
        return helpers.message({ custom: 'Please enter a valid Start Date' });
      }
      if (!endDate.isValid) {
        return helpers.message({ custom: 'Please enter a valid End Date' });
      }
      if (startDate.isValid && endDate.isValid && endDate < startDate) {
        return helpers.message({ custom: 'Start Date must be earlier than End Date' });
      }
    }
    return value;
  })),
  education: Joi.array().items(Joi.object()).optional(),
  industries: Joi.array().items(Joi.object()).optional(),
  emails: Joi.array().items(Joi.object()).optional(),
  phoneNumbers: Joi.array().items(Joi.object()).optional(),
  skills: Joi.array().items(Joi.object()).optional(),
  note: Joi.string().optional().allow('')
});

type FormFields = {
  firstName: string
  lastName: string
  profileUrl: string
  experiences: {
    companyName: string
    position: string
    startDate: Date | null
    endDate: Date | null
  }[]
  education: {
    institution: string
    degree: string
    course: string
  }[]
  industries: SelectOption[]
  skills: SelectOption[]
  emails: { value: string, type: SelectOption }[]
  phoneNumbers: { value: string, type: SelectOption }[]
  note: string
}

const defaultCandidateEmailTypeOption = CandidateEmailTypeOptions.find(item => item.value === CandidateEmailType.Professional);
const defaultCandidatePhoneTypeOption = CandidatePhoneTypeOptions.find(item => item.value === CandidatePhoneType.Professional);

function CandidateForm(props: Props) {

  const notifications = useNotifications();
  const [fetchCandidateError, setFetchCandidateError] = useState('');
  const [candidateLoading, setCandidateLoading] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: {errors, isSubmitting},
  } = useForm<FormFields>({
    resolver: joiResolver(schema),
    defaultValues: {
      experiences: [{companyName: '', position: '', startDate: null, endDate: null}],
      education: [{institution: '', degree: '', course: ''}],
      skills: [],
      industries: [],
      emails: [{ value: '', type: defaultCandidateEmailTypeOption }],
      phoneNumbers: [{ value: '', type: defaultCandidatePhoneTypeOption }]
    }
  })

  const {fields: experiencesField, append: appendExperiencesField, remove: removeExperiencesField} = useFieldArray({
    control,
    name: "experiences",
  });

  const {fields: educationField, append: appendEducationField, remove: removeEducationField} = useFieldArray({
    control,
    name: "education",
  });

  const {fields: emailsField, append: appendEmailsField, remove: removeEmailsField} = useFieldArray({
    control,
    name: "emails",
  });

  const {fields: phoneNumbersField, append: appendPhoneNumbersField, remove: removePhoneNumbersField} = useFieldArray({
    control,
    name: "phoneNumbers",
  });

  useEffect(() => {
    if (!props.candidateId) {
      return;
    }
    setCandidateLoading(true);
    candidateService.fetchById(props.candidateId).then(r => {
      setCandidateLoading(false);
      if (!r) {
        setFetchCandidateError("We couldn't load the candidate data. Please try again later");
        return;
      }
      reset({
        ...r,
        profileUrl: r?.socialProfiles?.find(item => item.site === 'linkedin')?.url || '',
        experiences: r?.experiences.map(item => {
          return {
            companyName : item.company.name,
            position : item.title,
            startDate : item.startDate ? DateTime.fromISO(item.startDate).toJSDate() : null,
            endDate : item.endDate ? DateTime.fromISO(item.endDate).toJSDate() : null,
          };
        }) || [{companyName: '', position: '', startDate: null, endDate: null}],
        education: r?.education.map(item => {
          return {
            institution: item.school.name,
            degree: item.degrees ? item.degrees[0] : '',
            course: item.majors ? item.majors[0] : ''
          }
        }) || [{institution: '', degree: '', course: ''}],
        emails: r.emails?.map(item => {
          return {
            value: item.address,
            type: CandidateEmailTypeOptions.find(opt => item.type as CandidateEmailType === opt.value)!,
          }
        }) || [{ value: '', type: defaultCandidateEmailTypeOption }],
        phoneNumbers: r.phoneNumbers?.map(item => {
          return {
            value: item.e164,
            type: CandidatePhoneTypeOptions.find(opt => item.type as CandidatePhoneType === opt.value)!,
          }
        }) || [{ value: '', type: defaultCandidatePhoneTypeOption }]
      });
    }).catch(err => {
      setCandidateLoading(false);
      setFetchCandidateError("Something went wrong during fetching the candidate data. Please try again later");
    })
  }, [props.candidateId])

  const onSubmit: SubmitHandler<FormFields> = async (values) => {
    const dto: CreateCandidateDTO = {
      firstName: values.firstName,
      lastName: values.lastName,
      profileUrl: values.profileUrl,
      socialProfiles: values.profileUrl ? [{
        site: 'linkedin',
        url: values.profileUrl
      }] : [],
      experiences: values.experiences.filter(item => !!item.companyName?.trim()).map(item => {
        return {
          title: item.position,
          startDate: item.startDate ? DateTime.fromJSDate(item.startDate).toISO() : '',
          endDate: item.endDate ? DateTime.fromJSDate(item.endDate).toISO() : '',
          company: {
            name: item.companyName
          }
        }
      }),
      education: values.education.filter(item => item.institution).map(item => {
        return {
          school: {
            name: item.institution
          },
          degrees: item.degree ? [item.degree] : [],
          majors: item.course ? [item.course] : [],
        }
      }),
      phoneNumbers: values.phoneNumbers.filter(item => !!item.value).map(item => {
        return {
          e164: item.value.toString().replace(/\D/g, ''),
          type: item.type.value as CandidatePhoneType,
        }
      }),
      emails: values.emails.filter(item => !!item.value).map(item => {
        return {
          address: item.value.toString(),
          type: item.type.value as CandidateEmailType,
        }
      }),
      skills: values.skills.filter(Boolean).map(item => item.value.toString()),
      ...((values.note && !props.candidateId) ? {
        note: {
          note: values.note
        }
      } : {})
    }
    try {
      await candidateService.createManually(dto);
      props.onSuccess();
      notifications.showSuccess('Candidate has been added');
    } catch (err) {
      alert("Couldn't create the candidate");
    }
  }
  function addExperience() {
    appendExperiencesField({
      companyName: '',
      position: '',
      startDate: null,
      endDate: null
    })
  }

  function addEducation() {
    appendEducationField({
      institution: '',
      degree: '',
      course: ''
    })
  }

  function addPhoneNumber() {
    appendPhoneNumbersField({
      value: '',
      type: defaultCandidatePhoneTypeOption!,
    })
  }

  function addEmail() {
    appendEmailsField({
      value: '',
      type: defaultCandidateEmailTypeOption!,
    })
  }

  if (candidateLoading) {
    return <Loading />;
  }

  return (
    <div className={css.holder}>
      <form className={css.flexVertical} onSubmit={handleSubmit(onSubmit)} style={{justifyContent: 'space-between', flex: 1, marginTop: 32}}>
        <div>
          <div className={css.formFieldsHolder}>
            <FormField error={errors.firstName? 'First Name is required' : ''} label="First Name">
              <Controller
                name='firstName'
                control={control}
                render={({field}) => <Input {...field} placeholder='Enter first name'/>}
              />
            </FormField>
            <FormField error={errors.lastName ? 'Last Name is required' : ''} label="Last Name">
              <Controller
                name='lastName'
                control={control}
                render={({field}) => <Input {...field} placeholder='Enter last name'/>}
              />
            </FormField>
            <FormField label="LinkedIn">
              <Controller
                name='profileUrl'
                control={control}
                render={({field}) => <Input {...field} placeholder='Enter profile url'/>}
              />
            </FormField>
          </div>
          <FieldsetCollapsible style={{marginTop: 32}} title='Contacts' isOptional={true}>
            <div className={css.formFieldsHolder}>
              {phoneNumbersField.map((item, index) => (
                <div className={css.formFieldsHolder} key={item.id}>
                  <button type='button' className={cn(css.removeButton, phoneNumbersField.length > 1 && css.removeButtonVisible)} onClick={() => removePhoneNumbersField(index)}>
                    <TrashIcon/>
                  </button>
                  <FormField label={`Phone Number ${phoneNumbersField.length > 1 ? index + 1 : ''}`}>
                    <Controller
                      name={`phoneNumbers.${index}.value`}
                      control={control}
                      render={({field}) => <InputPhone {...field} placeholder='Enter phone number'/>}
                    />
                    <Controller
                      name={`phoneNumbers.${index}.type`}
                      control={control}
                      render={({field}) => (
                        <Select
                          style={{ marginTop: 10 }}
                          placeholder="Choose phone number type"
                          options={CandidatePhoneTypeOptions}
                          {...field}
                        />
                      )}
                    />
                  </FormField>
                </div>
              ))}
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: -4}}>
                <button type='button' className={css.addButton} onClick={addPhoneNumber}>
                  <PlusIcon/>
                  Add Phone Number
                </button>
              </div>
            </div>
            <div className={css.formFieldsHolder} style={{ marginTop: 16 }}>
              {emailsField.map((item, index) => (
                <div className={css.formFieldsHolder} key={item.id}>
                  <button type='button' className={cn(css.removeButton, emailsField.length > 1 && css.removeButtonVisible)} onClick={() => removeEmailsField(index)}>
                    <TrashIcon/>
                  </button>
                  <FormField label={`Email ${emailsField.length > 1 ? index + 1 : ''}`}>
                    <Controller
                      name={`emails.${index}.value`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter email'/>}
                    />
                    <Controller
                      name={`emails.${index}.type`}
                      control={control}
                      render={({field}) => (
                        <Select
                          style={{ marginTop: 10 }}
                          placeholder="Choose email type"
                          options={CandidateEmailTypeOptions}
                          {...field}
                        />
                      )}
                    />
                  </FormField>
                </div>
              ))}
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: -4}}>
                <button type='button' className={css.addButton} onClick={addEmail}>
                  <PlusIcon/>
                  Add Email
                </button>
              </div>
            </div>
          </FieldsetCollapsible>
          <hr className={css.divider}/>
          <FieldsetCollapsible title='Experience' isOptional={true}>
            <div className={css.formFieldsHolder}>
              {experiencesField.map((item, index) => (
                <div className={css.formFieldsHolder} key={item.id}>
                  <button type='button' className={cn(css.removeButton, experiencesField.length > 1 && css.removeButtonVisible)} onClick={() => removeExperiencesField(index)}>
                    <TrashIcon/>
                  </button>
                  <FormField label="Company">
                    <Controller
                      name={`experiences.${index}.companyName`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter company name'/>}
                    />
                  </FormField>
                  <FormField label="Position">
                    <Controller
                      name={`experiences.${index}.position`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter position'/>}
                    />
                  </FormField>
                  <FormField error={(errors.experiences || [])[index]?.root?.message} label="Start Date">
                    <Controller
                      name={`experiences.${index}.startDate`}
                      control={control}
                      render={({field}) => <InputDate {...field} />}
                    />
                  </FormField>
                  <FormField label="End Date">
                    <Controller
                      name={`experiences.${index}.endDate`}
                      control={control}
                      render={({field}) => <InputDate {...field} />}
                    />
                  </FormField>
                  {experiencesField.length > 1 && index !== experiencesField.length - 1 &&
                    <hr className={css.divider} style={{marginTop: 8}}/>}
                </div>
              ))}
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: -4}}>
                <button type='button' className={css.addButton} onClick={addExperience}>
                  <PlusIcon/>
                  Add Experience
                </button>
              </div>
            </div>
          </FieldsetCollapsible>
          <hr className={css.divider}/>
          <FieldsetCollapsible title='Education' isOptional={true}>
            <div className={css.formFieldsHolder}>
              {educationField.map((item, index) => (
                <div className={css.formFieldsHolder} key={item.id}>
                  <button type='button' className={cn(css.removeButton, educationField.length > 1 && css.removeButtonVisible)} onClick={() => removeEducationField(index)}>
                    <TrashIcon/>
                  </button>
                  <FormField label="Institution">
                    <Controller
                      name={`education.${index}.institution`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter institution name'/>}
                    />
                  </FormField>
                  <FormField label="Degree">
                    <Controller
                      name={`education.${index}.degree`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter degee'/>}
                    />
                  </FormField>
                  <FormField label="Course">
                    <Controller
                      name={`education.${index}.course`}
                      control={control}
                      render={({field}) => <Input {...field} placeholder='Enter course'/>}
                    />
                  </FormField>
                  {educationField.length > 1 && index !== educationField.length - 1 &&
                    <hr className={css.divider} style={{marginTop: 8}}/>}
                </div>
              ))}
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: -4}}>
                <button type='button' className={css.addButton} onClick={addEducation}>
                  <PlusIcon/>
                  Add Education
                </button>
              </div>
            </div>
          </FieldsetCollapsible>
          <hr className={css.divider}/>
          <FieldsetCollapsible title='Skills' isOptional={true}>
            <div className={css.formFieldsHolder}>
              <FormField label="Technical Skills">
                <Controller
                  name={`skills`}
                  control={control}
                  render={({field}) => (
                    <Select
                      noOptionsMessage={() => 'Type and hit "enter" to add a skill'}
                      formatCreateLabel={v => <>Add <b style={{fontWeight: 500}}>{v}</b> skill</>}
                      isMulti={true}
                      creatable={true}
                      placeholder="Type to add a skill"
                      {...field}
                    />
                  )}
                />
              </FormField>
              <FormField label="Industries">
                <Controller
                  name={`industries`}
                  control={control}
                  render={({field}) => (
                    <Select
                      noOptionsMessage={() => 'Type and hit "enter" to add an industry'}
                      formatCreateLabel={v => <>Add <b style={{fontWeight: 500}}>{v}</b> industry</>}
                      isMulti={true}
                      creatable={true}
                      placeholder="Type to add an industry"
                      {...field}
                    />
                  )}
                />
              </FormField>
            </div>
          </FieldsetCollapsible>
          {!props.candidateId && (
            <>
              <hr className={css.divider} />
              <FieldsetCollapsible title='Notes' isOptional={true}>
                <Controller
                  name={`note`}
                  control={control}
                  render={({ field }) => <Textarea {...field} minRows={5}
                                                   placeholder='Enter anything you want to note about the candidate' />}
                />
              </FieldsetCollapsible>
            </>
          )}
        </div>
        <footer className={css.footer}>
          <Button loading={isSubmitting} type='submit'
                  className={css.button}>{isSubmitting ? 'Adding...' : 'Add candidate'}</Button>
        </footer>
      </form>
    </div>
  )
}

export default CandidateForm;
