import { humanize } from "inflected";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link as CustomLink } from "../../../../components/DiscoverNew/UI/Link/Link";
import { Formatter } from "../../../../components/DiscoverNew/util/Formatter";
import Table from "../../../../components/project/Table/Table";
import { Pill } from "../../../../components/ui/Pill/Pill";
import { AdminOrganizationMemberInterface, adminService } from "../../../../services/AdminService";
import { organizationService } from "../../../../services/OrganizationService";

type Props = {
  //match: any
  members: AdminOrganizationMemberInterface[]
  organizationId: string
}

export const AdminOrganizationMemberList = (props: Props) => {

  const [creditsUsage, setCreditsUsage] = useState<Record<string, number>>();
  const [creditsUsageLoading, setCreditsUsageLoading] = useState(false);

  useEffect(() => {
    setCreditsUsageLoading(true);
    adminService.fetchOrganizationCreditsUsageBySeats(props.organizationId).then(r => {
      setCreditsUsage(r.CandidateUnlockCreditsRedeemedBy);
      setCreditsUsageLoading(false);
    }).catch(err => {
      setCreditsUsageLoading(false);
    })
  }, []);

  const onEditMember = (sub: AdminOrganizationMemberInterface) => {

  };

  return (
    <Table
      noData="No members"
      actions={{
        //edit : {
        //  render : (v: any) => {
        //    return <Icon name="pencil" onClick={() => onEditMember(v)} className={css.editButton} />;
        //  },
        //},
      }}
      dataObject={{
        data : {
          //members: members ? members.nodes : []
          members : props.members || [],
        },
      }}
      columns={[
        {
          dataIndex : "name",
          title : "Name",
          render : (v: any, item: AdminOrganizationMemberInterface) => (

            <CustomLink className="table-link" to={`/admin/users/${item.id}`}>
              {Formatter.fullName(item)}
            </CustomLink>
          ),
        },
        { dataIndex : "email" },
        { dataIndex : "role", render : (v: string) => humanize(v) },
        {
          dataIndex : "status", render : (v: string, item: AdminOrganizationMemberInterface) => {
            return <Pill style={organizationService.getMemberStyle(item)}>{humanize(item.status)}</Pill>;
          },
        },
        {
          dataIndex : "credits", title : "Credits", render : (v: any, item: AdminOrganizationMemberInterface) => {
            return creditsUsageLoading ? <span>Loading...</span> : <span>{creditsUsage?.[item.id]?.toLocaleString() || 0} of {item.credits.limit?.toLocaleString() || 0}</span>
          },
        },
        {
          dataIndex : "invitedAt", title : "Added", style : { width : 110 }, render : (v: string) => {
            return v ? Formatter.fullDate(v) : undefined;
          },
        },
      ]}
    />
  );
};