import * as React from "react";
import { useState } from "react";
// @ts-ignore
import { Spinner } from "reactstrap";
import { useForm } from "../../../../hooks/useForm";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import {
  CandidateImportParseResult,
  CandidateImportStrategy,
  candidateImportStrategyOptions,
  candidateService,
} from "../../../../screens/projects/CandidateService";
import { projectService } from "../../../../screens/projects/ProjectService";
import { injectStyleOverride } from "../../../DiscoverNew/DiscoverNewWrapper";
import Stepper from "../../../ui/Stepper/Stepper";

import css from "./CandidateImport.module.css";
import {
  CandidateImportMapping,
  CandidateImportMappingStepValue,
  ColumnsMapping,
} from "./CandidateImportMapping/CandidateImportMapping";
import { CandidateImportResult } from "./CandidateImportResult";
import { CandidateImportSource } from "./CandidateImportSource";
import { CandidateImportUpload, CandidateImportUploadStepValue } from "./CandidateImportUpload";
import { CandidateImportContext } from "./ui/CandidateImportContext";

export const CandidateImport = (props: unknown) => {
  const { params } = useQueryParams();
  const [step, setStep] = useState(1);
  const [importParseResult, setImportParseResult] = useState<CandidateImportParseResult>();

  const { values, onChangeValues } = useForm<CandidateImportUploadStepValue & CandidateImportMappingStepValue>({
    project : undefined,
    file : undefined,
    importStrategy : candidateImportStrategyOptions[0],
    columnsMapping : {},
  });

  React.useEffect(() => {
    if (params.projectId) {
      projectService.fetchProject(params.projectId).then(r => {
        onChangeValues({ project : { value : r.id, label : r.name } });
      })
    }
  }, [params.projectId]);

  React.useEffect(() => {
    const cb = injectStyleOverride();
    const appContainer = document.querySelector("#app-container")! as HTMLDivElement;
    appContainer.style.display = "flex";
    appContainer.style.flexDirection = "column";
    return () => {
      cb();
      appContainer.style.display = "";
      appContainer.style.flexDirection = "";
    };
  }, []);

  const onSubmitSourceState = () => {
    setStep(step + 1);
  };

  const onSubmitUploadState = async (v: CandidateImportUploadStepValue, parseResult: CandidateImportParseResult) => {
    setImportParseResult(parseResult);
    onChangeValues({ ...v });
    setStep(step + 1);
  };

  const onSubmitMapping = async (enrich: boolean, columnsMapping: ColumnsMapping) => {
    onChangeValues({ columnsMapping });
    await candidateService.confirmCandidateImport(importParseResult!.uuid, {
      projectId : values.project?.value ? values.project.value as number : undefined,
      strategy : values.importStrategy.value as CandidateImportStrategy,
      enrich,
      columnsMapping,
    });
    setStep(step + 1);
  };

  return (
    <main className={css.container}>
      <header className={css.header}>
        <div className="max-width">
          <h1 className={css.title}>Import Candidates</h1>
          <Stepper steps={["Source", "Upload", "Map", "Review"]} activeStep={step} />
        </div>
      </header>
      <CandidateImportContext.Provider value={{ step, setStep }}>
        {step === 1 && <CandidateImportSource onSubmit={onSubmitSourceState} />}
        {step === 2 && <CandidateImportUpload onSubmit={onSubmitUploadState} value={values} />}
        {step === 3 && <CandidateImportMapping onSubmit={onSubmitMapping} data={importParseResult!} />}
        {step === 4 && <CandidateImportResult data={importParseResult!} projectId={values.project?.value as number} />}
      </CandidateImportContext.Provider>
    </main>
  );
};