import * as React from "react";
import css from "./ProjectPageControls.module.css";
import { Icon } from "../../../components/DiscoverNew/UI/IconComponent/Icon";
import capitalizeWords from "../../../formatters/capitalizeWords";
import { Button } from "../../../components/DiscoverNew/UI/Button/Button";
import { Link } from "react-router-dom";
import { useUserData } from "../../../contexts/userContext";
import { FilterSelectHtml } from "../../../components/DiscoverNew/UI/SelectHtml/FilterSelectHtml/FilterSelectHtml";
import { PageSubtitle } from "../../../components/ui/PageSubtitle/PageSubtitle";
import { SearchInput } from "../../../components/ui/SearchInput/SearchInput";
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg";
import { projectService } from "../../projects/ProjectService";
import { CandidateExportSelect, CandidatesExportMode } from "../../../components/ui/ExportSelect/CandidateExportSelect";
import {
  ModalCandidatesExportDelayed,
} from "../../../components/candidate/v2/ModalCandidatesExportDelayed/ModalCandidatesExportDelayed";
import { useNotifications } from "../../../components/DiscoverNew/contexts/Notification/notifications";
import { PermissionEnum, permissionService } from "../../projects/PermissionService";
import { ROUTES } from "../../../constants/constants";

export const ProjectPageControls = ({
  project,
  candidates,
  onChangeSort,
  sort,
  sortOptions,
  onChangeProjectStage,
  projectStage,
  projectStageOptions,
  query,
  onChangeQuery,
  selectedCandidates,
  onRemoveCandidates,
  workableJobs,
  selectedWorkableJob,
  onselectedWorkableJobChange,
  onAddingCandidatesToWorkable,
  jobSortOptions,
  onJobSortChange,
  selectedJobSort,
  reloadWorkableJobs,
}) => {
  const userData = useUserData();
  const uiSettings = userData.state.user.uiSettings;
  const [exportData, setExportData] = React.useState();
  const [isCandidatesExportDelayedModalOpen, setIsCandidatesExportDelayedModalOpen] = React.useState(false);
  const notifications = useNotifications();

  const canExportToCSV = permissionService.can(null, PermissionEnum.projectExportCandidate);
  const canImportCandidate = permissionService.can(null, PermissionEnum.projectImportCandidate);
  const canDiscover = permissionService.can(null, PermissionEnum.discover);

  // @todo refactor and move up to project.js
  const downloadProjectCSV = async (mode, force) => {
    if (!project?.projectId || !candidates?.length > 1) {
      return;
    }
    const verifiedOnly = mode === CandidatesExportMode.Verified;
    try {
      const result = await projectService.downloadPDF(project, { verifiedOnly, force });
      if (['delayed', 'ready'].includes(result.status)) {
        setExportData(result);
        setIsCandidatesExportDelayedModalOpen(true);
      } else {
        alert("Couldn't start download. Please contact with support.");
      }
    } catch (err) {
      alert("Couldn't start download. Please contact with support.");
      console.error("could not download project detail csv", err);
      // Sentry.captureException(err, { user: userData.state.user, extra: { location: 'ProjectPageControls' }, tags: { candidatesExport : 1 }  });
    }
  };

  const onRestartExport = async () => {
    await projectService.downloadPDF(project, { verifiedOnly: true, force: true });
    setIsCandidatesExportDelayedModalOpen(false);
    setExportData(undefined);
    notifications.showSuccess('Your export is being prepared. We will notify you when it is ready.');
  }

  return (
    <div>
      <div className={css.info}>
        <PageSubtitle>{capitalizeWords(uiSettings?.mappings?.candidates)}</PageSubtitle>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", flexWrap: "wrap" }}>
          <Button
            style={{ marginLeft: 8 }}
            theme="outline"
            className={css.discoverButton}
            component={Link}
            to={`/my-candidates/${project.projectId}`}
            leftIcon={<Icon name="board-view" />}
          >
            Talent Board
          </Button>
          {canExportToCSV && (
            <CandidateExportSelect label={
              <Button
                style={{ marginLeft: 8 }}
                disabled={!candidates?.nodes?.length}
                theme="outline"
                className={css.exportButton}
              >
                Export to CSV
              </Button>
            } onChange={downloadProjectCSV} />
          )}
          {canImportCandidate && (
            <Button
              style={{ marginLeft: 8 }}
              theme="outline"
              component={Link} to={ROUTES.candidatesImport + '?projectId=' + project.id}
            >
              Import from CSV
            </Button>
          )}
          {canDiscover && (
            <Button
              style={{ marginLeft: 8 }}
              theme="outline"
              className={css.discoverButton}
              component={Link}
              to={`/discover${project.tags ? `?skills=${project.tags}` : ""}`}
              leftIcon={<Icon name="plus" />}
            >
              Discover More
            </Button>
          )}
          {workableJobs.length > 1 && (
            <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", flexBasis: "100%" }}>
              <FilterSelectHtml
                options={jobSortOptions}
                onChange={onJobSortChange}
                label="Sort Jobs"
                value={selectedJobSort}
                style={{ marginRight: 4 }}
              />
              <FilterSelectHtml
                options={workableJobs}
                onChange={onselectedWorkableJobChange}
                label="Jobs"
                value={selectedWorkableJob}
              />
              <Button theme="outline" className={css.addWorkableButton} onClick={reloadWorkableJobs}>
                <ReloadIcon />
              </Button>
              <Button
                disabled={!Object.values(selectedCandidates).length || selectedWorkableJob.value === -1}
                theme="outline"
                className={css.addWorkableButton}
                onClick={onAddingCandidatesToWorkable}
              >
                Add to Workable
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={css.controls}>
        <div>
          <SearchInput
            value={query}
            onChange={(e) => onChangeQuery(e.target.value)}
            rightIcon={<Icon name="search" />}
            placeholder="Quick Search"
          />
          <FilterSelectHtml
            isIntValue
            style={{ marginLeft: 59 }}
            onChange={onChangeProjectStage}
            options={projectStageOptions}
            value={projectStage}
          />
          {!!Object.keys(selectedCandidates).length && (
            <Button theme="dangerOutlined" onClick={onRemoveCandidates} style={{ fontSize: 12, marginLeft: 12 }}>
              Remove Selected
            </Button>
          )}
        </div>
        <div>
          <FilterSelectHtml label="Sort By" onChange={onChangeSort} options={sortOptions} value={sort} />
        </div>
      </div>
      {isCandidatesExportDelayedModalOpen && (
        <ModalCandidatesExportDelayed
          onClose={() => setIsCandidatesExportDelayedModalOpen(false)}
          allowRestart={exportData?.allowRestart}
          onRestart={onRestartExport}
        />
      )}
    </div>
  );
};
