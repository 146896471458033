import * as React from 'react';
import cn from 'classnames';
import SectionTitle from "../../../../SectionTitle/SectionTitle";
import { Pill } from "../../../../ui/Pill/Pill";

import css from './ImportSourceRadioCard.module.css';

type Props = {
  isActive?: boolean
  isDisabled?: boolean
  isComingSoon?: boolean
  title: string
  helpText: string
  style?: React.CSSProperties
}

export const ImportSourceRadioCard = (props: Props) => {
  return (
    <div className={cn(css.card, {
      [css.active] : props.isActive,
      [css.disabled] : props.isDisabled
    })} style={props.style}>
      <div className={css.checkIcon} />
      <div className={css.titleHolder}>
        <SectionTitle level={3} className={css.title}>{props.title}</SectionTitle>
        {props.isComingSoon && <Pill style={{ color : "#408cf6", backgroundColor : "#dfecff" }}>Coming Soon!</Pill>}
      </div>
      <p className={css.helpText}>{props.helpText}</p>
    </div>
  )
}