import { OrganizationMemberRole } from "../services/OrganizationService";
import { axiosApiV2 } from "./apiService";

export enum v2_OrganizationType {
  Recruiter = 'recruiter',
  Sales = 'sales',
}

type CreateOrganizationDTO = {
  name: string
  type: string
}

export function v2_CreateOrganization(dto: CreateOrganizationDTO) {
  return axiosApiV2.post(`/organization`, dto);
}

export function v2_GetOrganization() {
  return axiosApiV2.get(`/organization`);
}

type UpdateOrganizationDTO = {
  name: string
}

export function v2_UpdateOrganization(dto: UpdateOrganizationDTO) {
  return axiosApiV2.put(`/organization`, dto);
}

type CreateOrganizationSubscriptionDTO = {
  planId: number
  seatCount: number
  period: 'monthly' | 'yearly' | 'one_time'
  secretKey?: string
  keepActive?: number[]
}

export function v2_CreateOrganizationSubscription(dto: CreateOrganizationSubscriptionDTO) {
  return axiosApiV2.post(`/organization/subscription`, dto);
}

export type CanInviteToOrganizationDTO = string[]

export function v2_CanInviteToOrganization(dto: CanInviteToOrganizationDTO) {
  return axiosApiV2.post(`/organization/can-invite`, dto);
}

type UpdateOrganizationSubscriptionDTO = {
  seatCount: number
}

export function v2_UpdateOrganizationSubscription(dto: UpdateOrganizationSubscriptionDTO) {
  return axiosApiV2.put(`/organization/subscription`, dto);
}

export function v2_GetOrganizationActivePlan() {
  return axiosApiV2.get(`/organization/subscription/plan`);
}

export function v2_GetOrganizationActiveSubscription() {
  return axiosApiV2.get(`/organization/subscription`);
}

export function v2_GetOrganizationActiveSubscriptionCreditsUsage() {
  return axiosApiV2.get(`/organization/subscription/usage/credits`);
}

export function v2_GetOrganizationSeatCreditsUsage() {
  return axiosApiV2.get(`/organization/seat/usage/credits`);
}

export function v2_GetOrganizationActiveSubscriptionCreditsUsageBySeat() {
  return axiosApiV2.get(`/organization/subscription/usage/credits/by-seat`);
}

export function v2_CancelOrganizationActiveSubscription() {
  return axiosApiV2.delete(`/organization/subscription`);
}

export function v2_RenewOrganizationActiveSubscription() {
  return axiosApiV2.post(`/organization/subscription/renew`);
}

type CreateOrganizationInviteDTO = {
  email: string
  role: OrganizationMemberRole
  seatRestrictions?: {
    limitCandidateUnlockCredits: number
    exportDisabled: boolean
    emailVerificationDisabled: boolean
    phoneVerificationDisabled: boolean
  }
}

export function v2_CreateOrganizationInvite(dto: CreateOrganizationInviteDTO) {
  return axiosApiV2.post(`/organization/invite`, dto);
}

export function v2_GetOrganizationMineInvites() {
  return axiosApiV2.get(`/organization/invite/mine`);
}

export function v2_GetOrganizationInvites({ query, limit, skip } = { query: '', limit: 40, skip: 0 }) {
  return axiosApiV2.get(`/organization/invite/list?query=${query}&limit=${limit}&skip=${skip}&accepted=false`);
}

export function v2_GetOrganizationInvitesCount({ query} = { query: ''}) {
  return axiosApiV2.get(`/organization/invite/count?query=${query}&accepted=false`);
}

export function v2_AcceptOrganizationInvite(inviteId: number) {
  return axiosApiV2.post(`/organization/invite/${inviteId}/accept`);
}

export function v2_DeclineOrganizationInvite(inviteId: number) {
  return axiosApiV2.post(`/organization/invite/${inviteId}/decline`);
}

export function v2_DeleteOrganizationInvite(inviteId: number | string) {
  return axiosApiV2.delete(`/organization/invite/${inviteId}`);
}

export function v2_ResendOrganizationInvite(inviteId: number | string) {
  return axiosApiV2.post(`/organization/invite/${inviteId}/resend`);
}

export function v2_GetOrganizationCurrentSeat() {
  return axiosApiV2.get(`/organization/seat`);
}

export function v2_GetOrganizationSeats({ query, limit= 40, skip = 0 } = { query: '', limit: 40, skip: 0 }) {
  return axiosApiV2.get(`/organization/seat/list?query=${query}&limit=${limit}&skip=${skip}`);
}

export function v2_GetOrganizationSeatsCount({ query } = { query: '' }) {
  return axiosApiV2.get(`/organization/seat/count?query=${query}`);
}

type UpdateOrganizationMemberDTO = {
  roleId?: number
  limitCandidateUnlockCredits?: number
  isActive?: boolean
}

export function v2_UpdateOrganizationMember(memberId: number | string, dto: UpdateOrganizationMemberDTO) {
  return axiosApiV2.put(`/organization/seat/${memberId}`, dto);
}

export function v2_DeleteOrganizationMember(memberId: number | string) {
  return axiosApiV2.delete(`/organization/seat/${memberId}`);
}

export function v2_GetOrganizationMemberRoles() {
  return axiosApiV2.get(`/organization/seat/roles`);
}

export function v2_GetAvailablePlans() {
  return axiosApiV2.get(`/organization/subscription/plan/available`);
}

export function v2_GetPreviewSubscriptionUpgrade({ seats } : { seats: number }) {
  return axiosApiV2.post(`/billing/preview/subscription-seat-upgrade`, { seats });
}

export function v2_GetBillingHistory({ query } = { query: '' }) {
  return axiosApiV2.get(`/billing/history/list?query=${query}&sortBy=desc`);
}

export function v2_GetInvoiceDownloadURL(invoiceId: string) {
  return axiosApiV2.get(`/billing/history/invoice/${invoiceId}`);
}

export function v2_GetPaymentMethods() {
  return axiosApiV2.get(`/billing/payment-method/list`);
}

export function v2_CreatePaymentMethod(id: string) {
  return axiosApiV2.post(`/billing/payment-method`, { id });
}

export function v2_DeletePaymentMethod(id: string) {
  return axiosApiV2.delete(`/billing/payment-method/${id}`);
}

export function v2_SetDefaultPaymentMethod(id: string) {
  return axiosApiV2.post(`/billing/payment-method/${id}/set-default`);
}

export function v2_AddOrganizationExtraCredits(units: number) {
  return axiosApiV2.post(`/organization/extra-credits`, { units });
}